import { ErrorMessage as FormikError } from 'formik'

import { ErrorMessage } from '@shared/components/ErrorMessage'

type Props = {
  fieldName: string
}

export function FormErrorMessage({ fieldName }: Props): JSX.Element {
  return <FormikError name={fieldName} render={(error) => <ErrorMessage message={error} />} />
}
