import { useEffect } from 'react'

import { useRouter } from 'next/router'

import { Form, Formik, FormikHelpers, FormikProps } from 'formik'
import { defineMessages, FormattedMessage, useIntl } from 'react-intl'

import { Box, Button } from '@themenu/design-system'

import { config } from '../../../config/client'
import { formFieldsValidation } from './Step1userContactInfo.validation'
import { Step } from '@common/constants'
import { updateStepRouteQueryParams } from '@common/utils/updateStepRouteQueryParams'
import { PhoneInput, TextInput } from '@components/index'
import SalesAvatarDialogue from '@components/SalesAvatarDialogue/SalesAvatarDialogue'
import { intlCommonForm } from '@i18n/intlMessages'
import { redirectWithLocale } from '@i18n/utils'
import Sentry from '@lib/sentry'
import { getAndSaveTrackingParams } from '@services/client/analytics/trackingParams'
import { sendDataToPardot } from '@services/client/pardot'
import { FormColumn, FormGrid, TunnelFormWrapper, TunnelStepWrapper } from '@shared/components'
import { LegalText } from '@shared/components/LegalText'
import { ActionType, useDispatchOnboardingAction, useOnboardingState } from '@src/store'
import { UserContact } from '@src/store/onboarding/OnboardingState'

const LINK_CONFIDENTIALITY = `${config.websiteUrl}/legal/confidentialite`

const messages = defineMessages({
  step1Intro: {
    id: 'affiliate.tunnel.step_1.intro',
  },
})

export function Step1UserContactInfo(): JSX.Element {
  const router = useRouter()
  const { formatMessage } = useIntl()

  const { userContact } = useOnboardingState()
  const dispatchOnboardingAction = useDispatchOnboardingAction()

  const handleSubmitUserContact = async (
    values: UserContact,
    formikHelpers: FormikHelpers<UserContact>
  ): Promise<void> => {
    try {
      await sendDataToPardot(values)

      dispatchOnboardingAction({
        type: ActionType.UPDATE_USER_CONTACT,
        payload: values,
      })
      updateStepRouteQueryParams(router, Step.COMPANY_INFO)
    } catch (error) {
      Sentry.withScope((scope) => {
        scope.setContext('/Home/Step1UserContactInfo#submitUserContact', {
          ...values,
        })
        Sentry.captureException(error)
      })
    }
    formikHelpers.setSubmitting(false)
  }

  useEffect(() => {
    getAndSaveTrackingParams()
  }, [])

  return (
    <TunnelStepWrapper data-testid="step-1-user-contact-info">
      <SalesAvatarDialogue message={formatMessage(messages.step1Intro)} />
      <TunnelFormWrapper>
        <Formik initialValues={userContact} validationSchema={formFieldsValidation} onSubmit={handleSubmitUserContact}>
          {(formik: FormikProps<UserContact>) => (
            <Form autoComplete="on">
              <Box mb={24}>
                <FormGrid>
                  <FormColumn>
                    <TextInput
                      id="firstName"
                      name="firstName"
                      label={formatMessage(intlCommonForm.firstName)}
                      placeholder={formatMessage(intlCommonForm.firstNamePlaceholder)}
                      inputProps={{
                        type: 'text',
                        autoComplete: 'given-name',
                      }}
                    />
                  </FormColumn>
                  <FormColumn>
                    <TextInput
                      id="lastName"
                      name="lastName"
                      label={formatMessage(intlCommonForm.lastName)}
                      placeholder={formatMessage(intlCommonForm.lastNamePlaceholder)}
                      inputProps={{
                        type: 'text',
                        autoComplete: 'family-name',
                      }}
                    />
                  </FormColumn>
                  <FormColumn>
                    <TextInput
                      id="email"
                      name="email"
                      label={formatMessage(intlCommonForm.email)}
                      placeholder={formatMessage(intlCommonForm.emailPlaceholder)}
                      inputProps={{
                        type: 'email',
                        autoComplete: 'email',
                      }}
                    />
                  </FormColumn>
                  <FormColumn>
                    <PhoneInput
                      id="phone"
                      name="phone"
                      label={formatMessage(intlCommonForm.phone)}
                      placeholder={formatMessage(intlCommonForm.phonePlaceholder)}
                      inputProps={{
                        type: 'phone',
                        autoComplete: 'tel',
                      }}
                    />
                  </FormColumn>
                </FormGrid>
              </Box>
              <Box mb={36}>
                <LegalText>
                  <FormattedMessage
                    id="affiliate.tunnel.step_1.privacy_policies"
                    values={{
                      privacy: (chunks: string) => (
                        <a target="_blank" href={redirectWithLocale(LINK_CONFIDENTIALITY)} rel="noreferrer">
                          {chunks}
                        </a>
                      ),
                      mailto: (chunks: string) => (
                        <a target="_blank" rel="noreferrer" href={`mailto:${chunks}`}>
                          {chunks}
                        </a>
                      ),
                    }}
                  />
                </LegalText>
              </Box>
              <Button type="submit" loading={formik.isSubmitting}>
                {formatMessage(intlCommonForm.submit)}
              </Button>
            </Form>
          )}
        </Formik>
      </TunnelFormWrapper>
    </TunnelStepWrapper>
  )
}
