import { AxiosError } from 'axios'

import { Step, SalesforceAccountStatus } from '@common/constants'
import { cntrToLocalMerchant, formatCompanyToMerchant, LocalMerchant } from '@common/utils/format'
import Sentry from '@lib/sentry'
import {
  upsertLead,
  findAccountOrUpsertLead,
  getCntrMerchants,
  searchSalesforceAccount,
} from '@services/client/affiliates'
import { getSalesforceAccountOwner, getSalesforceLeadOwner } from '@services/client/salesforce'
import { UserContact } from '@src/store/onboarding/OnboardingState'
import { Company, SfAccountInfo, SfLeadInfo, SalesforceOwner } from '@tunnel-types/globals'

export const isUnaffiliatedMerchant = (cntrMerchant: LocalMerchant): boolean => {
  return !cntrMerchant || !cntrMerchant.cntrAgreementNumber
}

export const getMerchant = async (company: Company): Promise<LocalMerchant> => {
  let cntrMerchant

  try {
    cntrMerchant = company.siret
      ? cntrToLocalMerchant(await getCntrMerchants(company.siret))
      : formatCompanyToMerchant(company)
  } catch (err) {
    cntrMerchant = formatCompanyToMerchant(company)
  }
  return cntrMerchant
}

export const isAlreadyConverted = async (siret: string, cntrAgreementNumber: string): Promise<boolean> => {
  if (!cntrAgreementNumber) {
    return false
  }
  const { CONTRACT_SIGNED, CONVERTED } = SalesforceAccountStatus

  try {
    const { result } = await searchSalesforceAccount(siret || '', cntrAgreementNumber)
    return result.salesforce_account?.status === CONTRACT_SIGNED || result.salesforce_account?.status === CONVERTED
  } catch (err) {
    const error = err as AxiosError
    if (error.response && error.response.status !== 404) {
      Sentry.withScope((scope) => {
        scope.setContext('/Home/Step2CompanyInfo/findNextStep.ts#isAlreadyConverted', {
          siret,
          cntrAgreementNumber,
        })
        Sentry.captureException(error)
      })
    }
    return false
  }
}

export const findNextStep = async (company: Company, locationsNumber: string): Promise<Step> => {
  const MINIMUM_LOCATIONS_COUNT = 1

  const cntrMerchant = await getMerchant(company)
  const isConvertedLead = await isAlreadyConverted(company.siret || '', cntrMerchant.cntrAgreementNumber || '')

  if (isUnaffiliatedMerchant(cntrMerchant)) {
    return Step.CNTR_UNAFFILIATED
  } else {
    if (isConvertedLead) {
      return Step.ALREADY_AFFILIATED
    }
    if (locationsNumber && parseInt(locationsNumber) > MINIMUM_LOCATIONS_COUNT) {
      return Step.MULTIPLE_RESTAURANTS
    }
    return Step.CONTRACT_INFO
  }
}

export const getSalesforceInfo = async (
  nextStep: Step,
  userContact: UserContact,
  company: Company,
  merchant: LocalMerchant
): Promise<SfAccountInfo | SfLeadInfo> => {
  return nextStep === Step.CONTRACT_INFO
    ? await findAccountOrUpsertLead(userContact, company, merchant)
    : await upsertLead(userContact, company, merchant)
}

export const isAffiliatesLead = (lead: SfAccountInfo): lead is SfLeadInfo => {
  return 'converted_account_sfid' in (lead as SfLeadInfo)
}

export const getSalesforceOwner = async (salesforceInfo: SfAccountInfo | SfLeadInfo): Promise<SalesforceOwner> => {
  return isAffiliatesLead(salesforceInfo)
    ? await getSalesforceLeadOwner({ sfid: salesforceInfo.sfid })
    : await getSalesforceAccountOwner({ sfid: salesforceInfo.sfid })
}
