import React from 'react'

import { NextPageContext, NextPage } from 'next'
import { useIntl } from 'react-intl'

import { intlErrorPage } from './ErrorPage.i18n'
import { BodyWrapper, LogoWrapper, Message, ButtonBlackPurple, StyledTypography } from './ErrorPage.style'
import { redirectWithLocale } from '@i18n/utils'

export type ErrorPageType = NextPage<{ statusCode: number }>

const ErrorPage: ErrorPageType = ({ statusCode }) => {
  const intl = useIntl()
  return (
    <BodyWrapper>
      <LogoWrapper>
        <a href={redirectWithLocale('https://www.swile.co')}>
          <img src="/images/logo-plain.svg" alt="logo-plain" />
        </a>
      </LogoWrapper>
      <Message>
        <a href={redirectWithLocale('https://www.swile.co')}>
          <img src="/images/logo-black.svg" alt="logo-black" />
        </a>
        <StyledTypography variant="h1">
          {statusCode == 404 ? (
            intl.formatMessage(intlErrorPage.notFound)
          ) : (
            <React.Fragment>
              {intl.formatMessage(intlErrorPage.messageTitle)}
              <br />
              {intl.formatMessage(intlErrorPage.messageBaseline)}
            </React.Fragment>
          )}
        </StyledTypography>
        <ButtonBlackPurple component="a" href={'/'}>
          {intl.formatMessage(intlErrorPage.backHome)}
        </ButtonBlackPurple>
      </Message>
    </BodyWrapper>
  )
}

ErrorPage.getInitialProps = ({ res }: NextPageContext) => {
  const statusCode = res?.statusCode || 500

  return {
    statusCode,
  }
}

export default ErrorPage
