import { useState } from 'react'

import { Form, Formik, FormikProps } from 'formik'
import { defineMessages, useIntl } from 'react-intl'

import { Box, Button } from '@themenu/design-system'

import Yup from '@common/utils/yup'
import { PhoneInput, TextInput } from '@components/index'
import { intlCommonForm } from '@i18n/intlMessages'
import Sentry from '@lib/sentry'
import { FormColumn, FormGrid, RecapHeader, RecapWrapper, StyledPenIcon } from '@shared/components'
import { ActionType, useDispatchOnboardingAction, useOnboardingState } from '@src/store'
import { UserContact } from '@src/store/onboarding/OnboardingState'
import { AccountOrLeadInfo } from '@tunnel-types/globals'

const messages = defineMessages({
  title: {
    id: 'step_3_recap.contact_form.contact',
  },
})

export const RecapContactFormValidationSchema = Yup.object().shape({
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
  email: Yup.string().email().required(),
  phone: Yup.string().internationalPhone().required(),
})

export default function RecapContactForm(): JSX.Element {
  const { formatMessage } = useIntl()
  const { userContact, salesforceInfo } = useOnboardingState()
  const dispatchOnboardingAction = useDispatchOnboardingAction()

  const [readOnly, setReadOnly] = useState<boolean>(RecapContactFormValidationSchema.isValidSync(userContact))

  const onCancel = (formik: FormikProps<UserContact>): void => {
    formik.setValues(userContact)
    setReadOnly(true)
  }

  const onSubmit = async (values: UserContact): Promise<void> => {
    try {
      dispatchOnboardingAction({
        type: ActionType.UPDATE_SALESFORCE_INFO,
        payload: {
          account: {
            ...(salesforceInfo.account as AccountOrLeadInfo),
            contact_person: {
              first_name: values.firstName,
              last_name: values.lastName,
              email: values.email,
              phone: values.phone,
            },
          },
          owner: salesforceInfo.owner,
        },
      })
      dispatchOnboardingAction({
        type: ActionType.UPDATE_USER_CONTACT,
        payload: values,
      })
      setReadOnly(true)
    } catch (error) {
      Sentry.withScope((scope) => {
        scope.setContext('/Home/Step3ContractInfo/RecapContactForm#submitUserContact', {
          ...values,
        })
        Sentry.captureException(error)
      })
    }
  }

  return (
    <Formik
      initialValues={userContact}
      validationSchema={RecapContactFormValidationSchema}
      onSubmit={onSubmit}
      validateOnMount
      enableReinitialize
    >
      {(formik: FormikProps<UserContact>) => {
        return (
          <Form autoComplete="on">
            <RecapWrapper error={Object.keys(formik.errors).length !== 0}>
              <RecapHeader
                title={formatMessage(messages.title)}
                action={
                  readOnly ? (
                    <Button type="submit" variant="secondary" size={40} onClick={() => setReadOnly(false)}>
                      <StyledPenIcon />
                      {formatMessage(intlCommonForm.edit)}
                    </Button>
                  ) : undefined
                }
              />
              <FormGrid>
                <FormColumn>
                  <TextInput
                    id="firstName"
                    name="firstName"
                    readOnly={readOnly}
                    label={formatMessage(intlCommonForm.firstName)}
                    placeholder={formatMessage(intlCommonForm.firstNamePlaceholder)}
                    inputProps={{
                      type: 'text',
                      autoComplete: 'firstName',
                    }}
                  />
                </FormColumn>
                <FormColumn>
                  <TextInput
                    id="lastName"
                    name="lastName"
                    readOnly={readOnly}
                    label={formatMessage(intlCommonForm.lastName)}
                    placeholder={formatMessage(intlCommonForm.lastNamePlaceholder)}
                    inputProps={{
                      type: 'text',
                      autoComplete: 'lastName',
                    }}
                  />
                </FormColumn>
                <FormColumn>
                  <TextInput
                    id="email"
                    name="email"
                    readOnly={readOnly}
                    label={formatMessage(intlCommonForm.email)}
                    placeholder={formatMessage(intlCommonForm.emailPlaceholder)}
                    inputProps={{
                      type: 'email',
                      autoComplete: 'email',
                    }}
                  />
                </FormColumn>
                <FormColumn>
                  <PhoneInput
                    id="phone"
                    name="phone"
                    readOnly={readOnly}
                    label={formatMessage(intlCommonForm.phone)}
                    placeholder={formatMessage(intlCommonForm.phonePlaceholder)}
                    inputProps={{
                      type: 'phone',
                      autoComplete: 'tel',
                    }}
                  />
                </FormColumn>
              </FormGrid>
              {!readOnly && (
                <Box display="flex" justifyContent="space-between" mt={4}>
                  <Button onClick={() => onCancel(formik)} type="button" variant="secondary" size={40}>
                    {formatMessage(intlCommonForm.cancel)}
                  </Button>
                  <Button type="submit" size={40}>
                    {formatMessage(intlCommonForm.confirm)}
                  </Button>
                </Box>
              )}
            </RecapWrapper>
          </Form>
        )
      }}
    </Formik>
  )
}
