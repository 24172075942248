import axios, { AxiosError } from 'axios'

import { i18n } from '../common/dispatch.i18n'
import { getProspect } from './pardot'
import Sentry from '@lib/sentry'
import { LocalMerchant } from '@src/common/utils/format'
import { UserContact } from '@src/store/onboarding/OnboardingState'

import type {
  SfAccountInfo,
  SfLeadInfo,
  Company,
  CntrMerchant,
  Prospect,
  MerchantGroup,
  ContractConsent,
} from '@tunnel-types/globals'

interface CreateContractResponse {
  merchant_group: MerchantGroup
}

interface SfAccountResponse {
  salesforce_account: SfAccountInfo
}

interface SearchSalesforceAccountResponse {
  result: SfAccountResponse
}

export const getProspectFromPardot = async (email: string): Promise<Prospect> => {
  try {
    const response = await getProspect({ email })
    return {
      category: response?.Lead_source_category || '',
      detail: response?.Lead_source_d_tail || '',
    }
  } catch (err) {
    Sentry.withScope((scope) => {
      scope.setContext('/services/affiliates.ts#getProspectFromPardot', {
        email,
      })
      Sentry.captureException(err)
    })
    return {
      category: '',
      detail: '',
    }
  }
}

export const upsertLead = async (
  userInfo: UserContact,
  company: Company,
  merchant: LocalMerchant
): Promise<SfLeadInfo> => {
  const prospect = await getProspectFromPardot(userInfo.email)
  const lead = await axios.post<SfLeadInfo>('api/affiliates/lead', {
    userInfo,
    company,
    merchant,
    prospect,
  })
  return lead.data
}

export const updateAccount = async (salesforceAccount: SfAccountInfo): Promise<SfAccountInfo> => {
  const { data } = await axios.patch<SfAccountInfo>('api/affiliates/account', {
    salesforceAccount,
  })

  return data
}

export const updateLead = async (salesforceLead: SfLeadInfo): Promise<SfLeadInfo> => {
  const { data } = await axios.patch<SfLeadInfo>('api/affiliates/lead', {
    salesforceLead,
  })

  return data
}

export const signContract = async (sfid: string, signature: string, consent: ContractConsent): Promise<void> => {
  await axios.post<SfLeadInfo>('api/affiliates/sign-contract', {
    sfid,
    signature,
    consent,
  })
}

export const addLeadPaymentTerminalKeys = async (sfid: string, paymentTerminalKeys: string[]): Promise<void> => {
  await axios.post<SfLeadInfo>('api/affiliates/lead-payment-terminal-keys', {
    sfid,
    paymentTerminalKeys,
  })
}

export const createContract = async (
  salesforceInfo: SfAccountInfo,
  signature: string,
  consent: ContractConsent
): Promise<CreateContractResponse> => {
  const { sfid, name, company_type, share_capital_cents, billing_address, billing_info, contact_person } =
    salesforceInfo

  const { data } = await axios.post<CreateContractResponse>('api/affiliates/create-contract', {
    merchant_group: {
      salesforce_account_id: sfid,
      name,
      company_type,
      share_capital_cents,
      billing_address,
      billing_info,
      contact_person,
    },
    signature,
    consent,
  })

  return data
}

export const findAccountOrUpsertLead = async (
  userInfo: UserContact,
  company: Company,
  merchant: LocalMerchant
): Promise<SfAccountInfo> => {
  try {
    const args = {
      cntrAgreementNumber: merchant.cntrAgreementNumber || '',
      siret: company.siret || '',
    }
    const { result } = await searchSalesforceAccount(args.siret, args.cntrAgreementNumber.toString())
    return result.salesforce_account
  } catch (err) {
    const error = err as AxiosError
    if (error.response?.status === 404) {
      return await upsertLead(userInfo, company, merchant)
    } else {
      throw i18n.error
    }
  }
}

export const searchSalesforceAccount = async (
  immatriculation: string,
  cntr_agreement_number: string
): Promise<SearchSalesforceAccountResponse> => {
  const { data } = await axios.get<SearchSalesforceAccountResponse>('/api/affiliates/account', {
    params: {
      immatriculation,
      cntr_agreement_number,
    },
  })
  return data
}

export const getCntrMerchants = async (siret: string): Promise<CntrMerchant> => {
  const { data } = await axios.get<CntrMerchant>('/api/affiliates/cntr-merchant', {
    params: {
      siret,
    },
  })
  return data
}

export const addMid = async (merchantId: string, mids: string[], sfid: string): Promise<void> => {
  await axios.patch('/api/affiliates/merchant', {
    merchantId,
    mids,
    sfid,
  })
}
