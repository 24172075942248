import { useRef } from 'react'

import { RadioGroup } from '@material-ui/core'
import { useIntl } from 'react-intl'

import { Box, COLORS, Typography } from '@themenu/design-system'

import { Step5SignatureMessages } from '../messages'
import RadioCanvasSignature from './RadioCanvasSignature'
import { FormColumn, FormGrid } from '@shared/components'
import { Contact } from '@tunnel-types/globals'

interface CustomCanvas extends HTMLCanvasElement {
  clear: () => void
}

const Fonts = {
  LA_BELLE_AURORE: 'LaBelleAurore',
  CEDARVILLE_CURSIVE: 'CedarvilleCursive',
} as const

function getFullname(contactPerson: Contact | undefined, defaultValue: string): string {
  const fragments = [contactPerson?.first_name, contactPerson?.last_name].filter(Boolean)
  return fragments.length ? fragments.join(' ') : defaultValue
}

type SignatureSelectionProps = {
  contactPerson: Contact | undefined
  onSignatureChecked: (content: string) => void
}

export function SignatureSelection({ contactPerson, onSignatureChecked }: SignatureSelectionProps): JSX.Element {
  const { formatMessage } = useIntl()

  const firstRef = useRef<CustomCanvas>(null)
  const secondRef = useRef<CustomCanvas>(null)
  const padRef = useRef<CustomCanvas>(null)

  const fullname = getFullname(contactPerson, formatMessage(Step5SignatureMessages.textSignaturePlaceholder))

  return (
    <Box mb={24}>
      <RadioGroup defaultValue="" name="customized-radios">
        <FormGrid>
          <FormColumn>
            <Typography variant="label" mb={12} color={COLORS.WHITE['900']}>
              {formatMessage(Step5SignatureMessages.textSignatureLabel)}
            </Typography>
            <Typography variant="body">{fullname}</Typography>
          </FormColumn>

          <FormColumn>
            <RadioCanvasSignature
              name="LaBelleAurore"
              font={Fonts.LA_BELLE_AURORE}
              signature={fullname}
              isHandWritten={false}
              onSignatureCheck={onSignatureChecked}
              refs={firstRef}
              padRef={padRef}
            />
            <RadioCanvasSignature
              name="CedarvilleCursive"
              font={Fonts.CEDARVILLE_CURSIVE}
              signature={fullname}
              isHandWritten={false}
              onSignatureCheck={onSignatureChecked}
              refs={secondRef}
              padRef={padRef}
            />
          </FormColumn>
        </FormGrid>

        <RadioCanvasSignature name="custom" isHandWritten onSignatureCheck={onSignatureChecked} padRef={padRef} />
      </RadioGroup>
    </Box>
  )
}
