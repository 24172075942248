import { useCallback, useState } from 'react'

import { useRouter } from 'next/router'

import { useIntl } from 'react-intl'

import { LegalText } from '../../../shared/components/LegalText'
import { isAffiliatesLead } from '../Step2CompanyInfo/helpers'
import { Step5SignatureMessages } from './messages'
import { SignatureForm } from './SignatureForm/SignatureForm'
import { FormValues } from './SignatureForm/validation'
import { SignatureSelection } from './SignatureSelection/SignatureSelection'
import { Step } from '@common/constants'
import { updateStepRouteQueryParams } from '@common/utils/updateStepRouteQueryParams'
import SalesAvatarDialogue from '@components/SalesAvatarDialogue/SalesAvatarDialogue'
import Sentry from '@lib/sentry'
import { createContract, signContract } from '@services/client/affiliates'
import { getTrackingParams } from '@services/client/analytics/trackingParams'
import { submitSignatureInfo, submitTrackingParams } from '@services/client/pardot'
import { TunnelFormWrapper, TunnelStepWrapper } from '@shared/components'
import { ActionType, useDispatchOnboardingAction, useOnboardingState } from '@src/store'

export function Step5Signature(): JSX.Element {
  const { formatMessage } = useIntl()
  const router = useRouter()

  const { salesforceInfo } = useOnboardingState()
  const dispatchOnboardingAction = useDispatchOnboardingAction()

  const [signatureContent, setSignatureContent] = useState('')

  const salesforceAccount = salesforceInfo.account as NonNullable<typeof salesforceInfo.account>

  const handleOnSubmit = useCallback(
    async (values: FormValues): Promise<void> => {
      try {
        await submitSignatureInfo(salesforceAccount.contact_person)
        await submitTrackingParams(getTrackingParams(), salesforceAccount.contact_person)

        const contractConsent = { termsOfService: values.termsOfService }

        if (isAffiliatesLead(salesforceAccount)) {
          await signContract(salesforceAccount.sfid, values.signatureText, contractConsent)
        } else {
          const { merchant_group } = await createContract(salesforceAccount, values.signatureText, contractConsent)

          dispatchOnboardingAction({
            type: ActionType.UPDATE_MERCHANT_GROUP_INFO,
            payload: {
              ...merchant_group,
              salesforce_account_id: salesforceAccount.sfid,
            },
          })
        }

        updateStepRouteQueryParams(router, Step.SUCCESS)
      } catch (err) {
        Sentry.withScope((scope) => {
          scope.setContext('/Home/Step5Signature#submitSignature', { ...values })
          Sentry.captureException(err)
        })
      }
    },
    [dispatchOnboardingAction, router, salesforceAccount]
  )

  return (
    <TunnelStepWrapper data-testid="step-5-signature">
      <TunnelFormWrapper>
        <SalesAvatarDialogue message={formatMessage(Step5SignatureMessages.pageTitle)} />

        <SignatureSelection contactPerson={salesforceAccount.contact_person} onSignatureChecked={setSignatureContent} />

        <LegalText large>{formatMessage(Step5SignatureMessages.legalText)}</LegalText>

        <SignatureForm signatureContent={signatureContent} onSubmit={handleOnSubmit} />
      </TunnelFormWrapper>
    </TunnelStepWrapper>
  )
}
