import React from 'react'

import { useIntl } from 'react-intl'

import { Box } from '@themenu/design-system'

import { config } from '../../../../config/client'
import { Step5SignatureMessages } from '../messages'
import CheckboxInput from '@components/Formik/CheckboxInput'
import { redirectWithLocale } from '@i18n/utils'

const LINK_PRIVACY_POLICY = `${config.websiteUrl}/legal/cgps-titres-resto-affilies`

type LinkTermsAndConditionsProps = {
  message: string
}

function LinkTermsAndConditions({ message }: LinkTermsAndConditionsProps): JSX.Element {
  return (
    <a target="_blank" href={redirectWithLocale(LINK_PRIVACY_POLICY)} rel="noreferrer">
      {message}
    </a>
  )
}

export function TermsAndConditions(): JSX.Element {
  const { formatMessage } = useIntl()
  const label = formatMessage(Step5SignatureMessages.termsOfService, {
    a: (linkMessage: string) => <LinkTermsAndConditions message={linkMessage} />,
  }) as React.ReactElement

  return (
    <Box mt={16} mb={20}>
      <CheckboxInput name="termsOfService" label={label} />
    </Box>
  )
}
