import { Step1UserContactInfo } from './Step1UserContactInfo/Step1UserContactInfo'
import { Step2CompanyInfo } from './Step2CompanyInfo/Step2CompanyInfo'
import { Step3ContractInfo } from './Step3ContractInfo/Step3ContractInfo'
import { Step4BankingInfo } from './Step4BankingInfo/Step4BankingInfo'
import { Step5Signature } from './Step5Signature/Step5Signature'
import { Step6Success } from './Step6Success/Step6Success'
import { Step } from '@common/constants'
import { Header, ProgressBar, Sidebar } from '@components/index'
import { usePushDataLayer } from '@hooks/usePushDataLayer'
import { useScrollToTopOnChange } from '@hooks/useScrollToTopOnChange'
import { TunnelParentContainer, TunnelStepContainer, TunnelViewContainer } from '@shared/components'
import { getFirstStep, useOnboardingRouteParams, useReconcileFirstNavigation } from '@src/common/navigation'
import { OnboardingStore } from '@src/store'
import { SalesforceInfo } from '@tunnel-types/globals'

function HomeComponent(): JSX.Element {
  useReconcileFirstNavigation()

  const { currentStep, tunnelType } = useOnboardingRouteParams()

  useScrollToTopOnChange(currentStep)
  usePushDataLayer()

  const isInitialStep = currentStep === getFirstStep(tunnelType)

  return (
    <TunnelParentContainer>
      <Sidebar />
      <TunnelViewContainer>
        {currentStep !== Step.SUCCESS && (
          <>
            <Header
              shouldDisplayLoginButton={currentStep === Step.CONTACT_INFO}
              shouldDisplayPreviousButton={!isInitialStep}
            />
            <ProgressBar tunnelType={tunnelType} currentStep={currentStep} />
          </>
        )}
        <TunnelStepContainer>
          {currentStep === Step.CONTACT_INFO && <Step1UserContactInfo />}
          {currentStep === Step.COMPANY_INFO && <Step2CompanyInfo />}
          {currentStep === Step.CONTRACT_INFO && <Step3ContractInfo />}
          {currentStep === Step.BANKING_INFO && <Step4BankingInfo />}
          {currentStep === Step.SIGNATURE && <Step5Signature />}
          {currentStep === Step.SUCCESS && <Step6Success />}
        </TunnelStepContainer>
      </TunnelViewContainer>
    </TunnelParentContainer>
  )
}

type ServerSideProps = {
  salesforceInfoFromUrl?: SalesforceInfo
}

export function Home({ salesforceInfoFromUrl }: ServerSideProps): JSX.Element {
  return (
    <OnboardingStore existingSalesforceInfo={salesforceInfoFromUrl}>
      <HomeComponent />
    </OnboardingStore>
  )
}
