import { useEffect } from 'react'

import { useFormikContext } from 'formik'

/**
 * Touched state is necessary to display the error messages but is only set on blur by formik.
 * While this behaviour is coherent for input fields to prevent displying an error message
 * when the user is still changing the value, it causes poor UX for required checkboxes since
 * unchecking the box does not display the 'required'error message
 */
export function useTouchedOnChange<Values extends Record<string, unknown>>(
  fieldName: Extract<keyof Values, string>
): void {
  const formik = useFormikContext<Values>()

  useEffect(() => {
    const isUntouched = !formik.touched[fieldName]
    const hasChanged = formik.values[fieldName] !== formik.initialValues[fieldName]

    if (isUntouched && hasChanged) {
      return () => formik.setFieldTouched(fieldName)
    }
  }, [formik, fieldName])
}
