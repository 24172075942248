import React from 'react'

import { AvatarDialogue } from '@shared/components'

const logoBlack = '/images/logo-black-circle.svg'

export const DEFAULT_PROFILE = {
  userName: 'Swile',
  src: logoBlack,
}

const SALESFORCE_OWNER_DEFAULT_FIRSTNAME = 'Sacha'
const IMAGE_BASE64_ENCODER = 'data:image/png;base64'

type SalesAvatarProps = {
  message: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  salesforceOwner?: any /* todo replace with sf type*/
  forceToDefaultAvatar?: boolean
  forceToDefaultAvatarSrc?: boolean
  defaultAvatarName?: string
  defaultAvatarSrc?: string
}

/*
 * 3 different cases
 * - salesforceOwner has name & src available
 * - salesforceOwner has a name but no image
 * - salesforceOwner has no name and no image
 * - salesforceOwner image needs to be replaced
 * */
const SalesAvatarDialogue: React.FunctionComponent<SalesAvatarProps> = ({
  forceToDefaultAvatar = false,
  forceToDefaultAvatarSrc = false,
  defaultAvatarName = DEFAULT_PROFILE.userName,
  defaultAvatarSrc = DEFAULT_PROFILE.src,
  ...props
}) => {
  const displayDefaultOwner =
    !props.salesforceOwner ||
    props.salesforceOwner.firstName === SALESFORCE_OWNER_DEFAULT_FIRSTNAME ||
    forceToDefaultAvatar

  const avatarProfileData = {
    name: props.salesforceOwner && !displayDefaultOwner ? props.salesforceOwner.firstName : defaultAvatarName,
    image:
      props.salesforceOwner?.image && !displayDefaultOwner && !forceToDefaultAvatarSrc
        ? `${IMAGE_BASE64_ENCODER},${props.salesforceOwner.image}`
        : defaultAvatarSrc,
  }

  return <AvatarDialogue message={props.message} avatar={avatarProfileData} />
}
export default SalesAvatarDialogue
