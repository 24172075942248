import { useState, useCallback, ChangeEvent } from 'react'

import { CircularProgress } from '@material-ui/core'
import throttle from 'lodash/throttle'
import { defineMessages, useIntl } from 'react-intl'
import styled from 'styled-components'

import { Box, Typography, TypographyProps, COLORS } from '@themenu/design-system'

import { ScrollList } from '@components/index'
import Sentry from '@lib/sentry'
import { getCompanyInfoByName } from '@services/client/company'
import { getSocieteInfoData } from '@services/client/societeInfo'
import { FormSearchInput, ListItem } from '@shared/components'
import { Company } from '@tunnel-types/globals'

const COMPANY_NAME_MINIMUM_LENGTH = 2
const SOCIETE_INFO_DEFAULT_PAGE = 1

const messages = defineMessages({
  defaultPlaceholder: {
    id: 'step_2_company_info.search_by_name.input.placeholder',
  },
  siretRedirect: {
    id: 'step_2_company_info.search_by_name.look_by_siret.redirect',
  },
  companyNameNotFoundError: {
    id: 'step_2_company_info.search_by_name.company_name.error_not_found',
  },
  companyInfoNotfoundError: {
    id: 'step_2_company_info.search_by_name.company_info.error_not_found',
  },
  searchIconAltText: {
    id: 'step_2_company_info.input.search_icon.alt_text',
  },
})

const StyledUnderlineText = styled(Typography)<TypographyProps<'button'>>`
  cursor: pointer;
  text-decoration: underline;
`

type ErrorContainerProps = {
  message: string
}

const ErrorContainer = ({ message }: ErrorContainerProps): JSX.Element => (
  <Box mt={16}>
    <Typography color={COLORS.PEACH['200']} weight="medium" variant="body">
      {message}
    </Typography>
  </Box>
)

interface SearchByNameProps {
  handleOnSelect: (company: Company) => void
  onViewChange: () => void
}

const SearchByName = ({ handleOnSelect, onViewChange }: SearchByNameProps): JSX.Element => {
  const { formatMessage } = useIntl()
  const [companiesList, setCompaniesList] = useState<Company[]>([])
  const [searchedCompany, setSearchedCompany] = useState<string>('')
  const [selectedCompany, setSelectedCompany] = useState<Company | undefined>(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  // error handlers
  const [isSearchByError, setIsSearchByError] = useState<boolean>(false)
  const [isCompanyInfoNotFoundError, setIsCompanyInfoNotFoundError] = useState<boolean>(false)
  // societe info pagination handlers
  const [currentPage, setCurrentPage] = useState<number>(SOCIETE_INFO_DEFAULT_PAGE)
  const [totalPages, setTotalPages] = useState<number>(0)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttleLoadData = useCallback(
    throttle(async (name, page) => await getSocieteInfoData(name, page), 10),
    []
  )

  const handleOnLoadMore = async (): Promise<void> => {
    if (currentPage < totalPages) {
      try {
        const nextPage = currentPage + 1

        const response = await throttleLoadData(searchedCompany, nextPage)

        if (response?.data) {
          const { companies, currentPage } = response.data
          setCompaniesList([...companiesList, ...companies])
          setCurrentPage(currentPage)
        }
      } catch (err) {
        Sentry.withScope((scope) => {
          scope.setContext('SearchByName#handleOnLoadMore', { searchedCompany })
          Sentry.captureException(err)
        })
      }
    }
  }

  const handleOnSearch = async (e: ChangeEvent<HTMLInputElement>): Promise<void> => {
    const { value } = e.target

    setIsCompanyInfoNotFoundError(false)
    setIsSearchByError(false)
    setIsLoading(true)

    if (value.length > COMPANY_NAME_MINIMUM_LENGTH) {
      try {
        setSearchedCompany(value)

        const {
          data: { companies, currentPage, totalPages },
        } = await getSocieteInfoData(value)

        if (companies.length === 0) {
          setIsCompanyInfoNotFoundError(true)
          setCompaniesList([])
        } else {
          setCompaniesList(companies)
          setTotalPages(totalPages)
          setCurrentPage(currentPage)
        }
      } catch (err) {
        setIsCompanyInfoNotFoundError(true)

        Sentry.withScope((scope) => {
          scope.setContext('SearchByName#handleOnSearch', {
            companyName: value,
          })
          Sentry.captureException(err)
        })
      }
    } else {
      setCompaniesList([])
    }

    setIsLoading(false)
  }

  const handleOnSelectedCompany = async (selectedCompany: Company): Promise<void> => {
    setIsSearchByError(false)
    setIsLoading(true)
    setSelectedCompany(selectedCompany)

    try {
      const { zip, name } = selectedCompany

      const company = await getCompanyInfoByName(name, zip)

      if (company) {
        handleOnSelect(company)
      } else {
        setIsSearchByError(true)
      }
    } catch (err) {
      setIsSearchByError(true)
      Sentry.withScope((scope) => {
        scope.setContext('SearchByName#handleOnSelectedCompany', { ...selectedCompany })
        Sentry.captureException(err)
      })
    }

    setIsLoading(false)
  }

  return (
    <Box>
      <FormSearchInput
        id="searchByCompanyName"
        searchIcon={{
          altText: formatMessage(messages.searchIconAltText),
          src: '/images/drawer/search.svg',
        }}
        onChange={handleOnSearch}
        placeholder={formatMessage(messages.defaultPlaceholder)}
      />
      <Box mt={12}>
        <StyledUnderlineText color={COLORS.WHITE['900']} onClick={onViewChange} weight="medium" variant="body">
          {formatMessage(messages.siretRedirect)}
        </StyledUnderlineText>
      </Box>
      {isSearchByError && <ErrorContainer message={formatMessage(messages.companyNameNotFoundError)} />}
      {isCompanyInfoNotFoundError && <ErrorContainer message={formatMessage(messages.companyInfoNotfoundError)} />}
      <Box mt={20}>
        {isLoading && !selectedCompany && (
          <Box alignItems="center" display="flex" justifyContent="center" mt={40}>
            <CircularProgress size="30px" />
          </Box>
        )}
        <ScrollList
          items={companiesList}
          loadMore={handleOnLoadMore}
          renderItem={(company: Company) => (
            <ListItem
              firstLineText={company.formattedAddress}
              isLoading={isLoading && selectedCompany && selectedCompany.id === company.id}
              onClick={() => handleOnSelectedCompany(company)}
              title={company.name}
            />
          )}
        />
      </Box>
    </Box>
  )
}

export default SearchByName
