import { defineMessages, FormattedMessage } from 'react-intl'

import { Box } from '@themenu/design-system'

import { DEFAULT_COMMISSION_VALUE } from './Step3ContractInfo'
import { LegalText } from '@shared/components/LegalText'

import type { AccountOrLeadInfo } from '@tunnel-types/globals'

const messages = defineMessages({
  feeExplanation: {
    id: 'step_3_recap.fee_explanation',
    defaultMessage:
      'For each payment made by a Swile card holder (outside the Platform), whether it is a payment at the Establishment, an online payment to the Establishment, or a payment via a Third Party Platform, Swile will invoice {commission} (this amount is understood to be before taxes) of the amount of the payment to the Establishment, including taxes.',
  },
})

type FeeExplanationMessageProps = {
  salesForceAccount: AccountOrLeadInfo | undefined
}

export function FeeExplanationMessage({ salesForceAccount }: FeeExplanationMessageProps): JSX.Element {
  const { meal_voucher_commission_percent = DEFAULT_COMMISSION_VALUE } = salesForceAccount ?? {}

  const commissionValue =
    meal_voucher_commission_percent > DEFAULT_COMMISSION_VALUE
      ? `${meal_voucher_commission_percent / 100}%`
      : `${meal_voucher_commission_percent}%`

  return (
    <Box mb={20}>
      <LegalText>
        <FormattedMessage {...messages.feeExplanation} values={{ commission: commissionValue }} />
      </LegalText>
    </Box>
  )
}
