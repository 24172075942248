import { isValidPhoneNumber } from 'react-phone-number-input'
import * as yup from 'yup'

import { intlValidation } from '@i18n/yup'

yup.addMethod(yup.string, 'internationalPhone', function phoneValidator(message: string) {
  const phoneFrRegex = /^(0|\+33|\+377|\+26|\+59)[1-9]([-. ]?[0-9]{2}){4}$/
  const phoneBrRegex = /^\s*(\d{2}|\d{0})[-. ]?(\d{5}|\d{4})[-. ]?(\d{4})[-. ]?\s*$/

  return this.test('valid', message, function isValidPhone(value) {
    if (value) {
      const isValid = phoneFrRegex.test(value) || phoneBrRegex.test(value) || isValidPhoneNumber(value)

      if (!isValid) {
        return this.createError({ path: this.path, message })
      }
    }

    return true
  })
})

yup.addMethod(yup.string, 'frPostalCode', function postalCodeValidator(message: string) {
  const postalCodeRegex = /^(([0-8][0-9])|(9[0-8]))[0-9]{3}$/

  return this.test('valid', message, function isValidPhone(value) {
    if (value) {
      const isValid = postalCodeRegex.test(value)

      if (!isValid) {
        return this.createError({ path: this.path, message })
      }
    }

    return true
  })
})

yup.addMethod(yup.string, 'brPostalCode', function postalCodeValidator(message: string) {
  const postalCodeRegex = /^\d{5}-?\d{3}$/

  return this.test('valid', message, function isValidPhone(value) {
    if (value) {
      const isValid = postalCodeRegex.test(value)

      if (!isValid) {
        return this.createError({ path: this.path, message })
      }
    }

    return true
  })
})

yup.addMethod(yup.string, 'isNumber', function integerValidator(message: string) {
  const integerRegex = /^\d+$/

  return this.test('valid', message, function isValidPhone(value) {
    if (value) {
      const isValid = integerRegex.test(value)

      if (!isValid) {
        return this.createError({ path: this.path, message })
      }
    }

    return true
  })
})

yup.setLocale({
  mixed: {
    default: intlValidation.default,
    notType: intlValidation.default,
    required: intlValidation.required,
  },
  string: {
    email: intlValidation.email,
    min: (props) => ({
      ...intlValidation.stringMin,
      values: props,
    }),
    max: (props) => ({
      ...intlValidation.stringMax,
      values: props,
    }),
  },
  number: {
    min: (props) => ({
      ...intlValidation.numberMin,
      values: props,
    }),
    max: (props) => ({
      ...intlValidation.numberMax,
      values: props,
    }),
  },
  date: {
    min: (props) => ({
      ...intlValidation.dateMin,
      values: props,
    }),
    max: (props) => ({
      ...intlValidation.dateMax,
      values: props,
    }),
  },
})

export default yup
