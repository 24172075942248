import { TextField } from '@material-ui/core'
import styled from 'styled-components'

import { COLORS } from '@themenu/design-system'

export const InputWrapper = styled(TextField)`
  /* default */
  .MuiInput-underline:before {
    border: 0;
  }

  /* hover (double-ampersand needed for specificity reasons. */
  .MuiInput-underline:hover:before {
    border: 0;
  }

  /* focused */
  .MuiInput-underline:after {
    border: 0;
  }

  & input::placeholder {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    /* identical to box height, or 133% */

    display: flex;
    align-items: center;
    color: ${COLORS.PURPLE['900']};
    opacity: 0.7;
  }

  & input {
    font-size: 15px;
    line-height: 20px;
    color: ${COLORS.PURPLE['900']};
  }
`
export const MidSuspucious = styled.span`
  padding-inline-start: 1rem;
  color: rgb(255, 0, 131);
`
