import React from 'react'

import { useFormikContext } from 'formik'

import { Button } from '@themenu/design-system'

type Props = {
  children: React.ReactNode
}

export function SubmitButton({ children }: Props): JSX.Element {
  const formik = useFormikContext()

  const canSubmit = formik.dirty && formik.isValid

  return (
    <Button type="submit" disabled={!canSubmit} loading={formik.isSubmitting} mt={24}>
      {children}
    </Button>
  )
}
