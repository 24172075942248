import { Step5SignatureMessages } from '../messages'
import Yup from '@common/utils/yup'

export type FormValues = {
  signatureText: string
  termsOfService: boolean
}

export const initialValues: FormValues = {
  signatureText: '',
  termsOfService: false,
}

export const formFieldsValidation = Yup.object<Record<keyof FormValues, Yup.AnySchema>>({
  signatureText: Yup.string().required(Step5SignatureMessages.signatureRequired),
  termsOfService: Yup.boolean()
    .required(Step5SignatureMessages.termsOfServiceRequired)
    .oneOf([true], Step5SignatureMessages.termsOfServiceRequired),
})
