import { useEffect, useMemo, useState } from 'react'

import { useRouter } from 'next/router'

import { defineMessages, useIntl } from 'react-intl'

import { Button } from '@themenu/design-system'

import { FeeExplanationMessage } from './FeeExplanationMessage'
import { Step } from '@common/constants'
import { updateStepRouteQueryParams } from '@common/utils/updateStepRouteQueryParams'
import SalesAvatarDialogue from '@components/SalesAvatarDialogue/SalesAvatarDialogue'
import { intlCommonForm } from '@i18n/intlMessages'
import Sentry from '@lib/sentry'
import RecapAddressForm, {
  RecapAddressValidationFormSchema,
} from '@pages-components/Home/Step3ContractInfo/RecapAddressForm/RecapAddressForm'
import RecapCompanyForm, {
  RecapCompanyFormValidationSchema,
} from '@pages-components/Home/Step3ContractInfo/RecapCompanyForm/RecapCompanyForm'
import RecapContactForm, {
  RecapContactFormValidationSchema,
} from '@pages-components/Home/Step3ContractInfo/RecapContactForm/RecapContactForm'
import { getAndSaveTrackingParams } from '@services/client/analytics/trackingParams'
import { submitContractInfo } from '@services/client/pardot'
import { TunnelFormWrapper, TunnelStepWrapper } from '@shared/components'
import { useOnboardingState } from '@src/store'

export const DEFAULT_COMMISSION_VALUE = 3.5

export const messages = defineMessages({
  dialogueMessage: {
    id: 'step_3_recap.avatar_dialogue.message',
    defaultMessage:
      'Here is the information provided by the Commission Nationale des Titres-Restaurant. In order to finalize your registration, please review and complete it if necessary.',
  },
})

export function Step3ContractInfo(): JSX.Element {
  const { formatMessage } = useIntl()

  const { companyInfo, userContact, salesforceInfo } = useOnboardingState()

  const router = useRouter()

  const [isLoading, setLoading] = useState(false)
  const isValid = useMemo(() => {
    const companyValid = RecapCompanyFormValidationSchema.isValidSync(companyInfo)
    const contactValid = RecapContactFormValidationSchema.isValidSync(userContact)
    const addressValid = RecapAddressValidationFormSchema.isValidSync(companyInfo.address)

    return companyValid && contactValid && addressValid
  }, [userContact, companyInfo])

  const handleOnSubmit = async (): Promise<void> => {
    setLoading(true)

    try {
      await submitContractInfo(userContact, companyInfo)

      updateStepRouteQueryParams(router, Step.BANKING_INFO)
    } catch (err) {
      Sentry.withScope((scope) => {
        scope.setContext('/Home/Step3Recap#submitRecap', { isValid })
        Sentry.captureException(err)
      })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getAndSaveTrackingParams()
  }, [])

  return (
    <TunnelStepWrapper data-testid="step-3-contract-info">
      <TunnelFormWrapper>
        <SalesAvatarDialogue message={formatMessage(messages.dialogueMessage)} />

        <RecapCompanyForm />
        <RecapContactForm />
        <RecapAddressForm />

        <footer>
          <FeeExplanationMessage salesForceAccount={salesforceInfo?.account} />
          <Button type="submit" onClick={handleOnSubmit as never} disabled={!isValid} loading={isLoading}>
            {formatMessage(intlCommonForm.submit)}
          </Button>
        </footer>
      </TunnelFormWrapper>
    </TunnelStepWrapper>
  )
}
