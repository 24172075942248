import React, { useState, Dispatch, SetStateAction } from 'react'

import { defineMessages, useIntl } from 'react-intl'

import SearchByName from './SearchByName'
import SearchBySiret from './SearchBySiret'
import { Company } from '@tunnel-types/globals'

import type { ValueOf } from 'type-fest'

const VIEW = {
  SEARCH_BY_NAME: 'SEARCH_BY_NAME',
  SEARCH_BY_SIRET: 'SEARCH_BY_SIRET',
} as const

type VIEW = ValueOf<typeof VIEW>

const messages = defineMessages({
  drawerDefaultTitle: {
    id: 'step_2_company_info.drawer.default.title',
  },
  drawerSiretTitle: {
    id: 'step_2_company_info.drawer.siret.title',
  },
})

interface Props {
  handleOnSelect: (company: Company) => void
  handleOnTitleChange: Dispatch<SetStateAction<string>>
}

const SearchCompanyInfo = ({ handleOnSelect, handleOnTitleChange }: Props): JSX.Element => {
  const { formatMessage } = useIntl()
  const [currentView, setCurrentView] = useState<VIEW>(VIEW.SEARCH_BY_NAME)

  const handleOnViewChange = (): void => {
    const updatedView = currentView === VIEW.SEARCH_BY_NAME ? VIEW.SEARCH_BY_SIRET : VIEW.SEARCH_BY_NAME
    const updatedTitle =
      currentView === VIEW.SEARCH_BY_NAME
        ? formatMessage(messages.drawerSiretTitle)
        : formatMessage(messages.drawerDefaultTitle)

    setCurrentView(updatedView)
    handleOnTitleChange(updatedTitle)
  }

  return (
    <>
      {currentView === VIEW.SEARCH_BY_NAME && (
        <SearchByName handleOnSelect={handleOnSelect} onViewChange={handleOnViewChange} />
      )}
      {currentView === VIEW.SEARCH_BY_SIRET && (
        <SearchBySiret handleOnSelect={handleOnSelect} onViewChange={handleOnViewChange} />
      )}
    </>
  )
}

export default SearchCompanyInfo
