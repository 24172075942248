import { useEffect } from 'react'

import { Form, Formik, useFormikContext } from 'formik'
import { useIntl } from 'react-intl'

import { useTouchedOnChange } from '../../../../hooks/useTouchedOnChange'
import { Step5SignatureMessages } from '../messages'
import { TermsAndConditions } from './TermsAndConditions'
import { formFieldsValidation, FormValues, initialValues } from './validation'
import { FormErrorMessage } from '@components/Formik/FormErrorMessage'
import { SubmitButton } from '@components/Formik/SubmitButton'

type SignatureFormProps = {
  signatureContent: string
  onSubmit: (values: FormValues) => void | Promise<void>
}

function SignatureFormContent({ signatureContent }: Pick<SignatureFormProps, 'signatureContent'>): JSX.Element {
  const { formatMessage } = useIntl()
  const formik = useFormikContext<FormValues>()

  useEffect(
    function signatureContentChanged() {
      if (signatureContent !== formik.values.signatureText) {
        formik.setFieldValue('signatureText', signatureContent)
      }
    },
    [formik, signatureContent]
  )

  useTouchedOnChange('termsOfService')
  useTouchedOnChange('signatureText')

  return (
    <Form>
      <TermsAndConditions />
      <FormErrorMessage fieldName="signatureText" />
      <SubmitButton>{formatMessage(Step5SignatureMessages.submitButton)}</SubmitButton>
    </Form>
  )
}

export function SignatureForm({ signatureContent, onSubmit }: SignatureFormProps): JSX.Element {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={formFieldsValidation}
      onSubmit={onSubmit}
      enableReinitialize
    >
      <SignatureFormContent signatureContent={signatureContent} />
    </Formik>
  )
}
