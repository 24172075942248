import { useEffect, useState } from 'react'

import { defineMessages, FormattedMessage, useIntl } from 'react-intl'

import { Button } from '@themenu/design-system'

import { config } from '../../../config/client'
import * as gtag from '../../../services/analytics/googleTag'
import { ButtonsWrapper, QualificationText, QualificationTitle } from './Step6Success.style'
import Mids from '@components/Mids/Mids'
import { redirectWithLocale } from '@i18n/utils'
import Sentry from '@lib/sentry'
import { addLeadPaymentTerminalKeys, addMid } from '@services/client/affiliates'
import { TunnelFormWrapper, TunnelStepWrapper } from '@shared/components'
import { useOnboardingState } from '@src/store'

import type { MerchantGroup } from '@tunnel-types/globals'

interface MidProps {
  merchantGroup?: MerchantGroup
  salesforceLeadId?: string
}

const messages = defineMessages({
  allGood: {
    id: 'step_6_success.all_good',
  },
  affiliationValidated: {
    id: 'step_6_success.affiliation_validated',
  },
  welcome: {
    id: 'step_6_success.welcome',
  },
  contract: {
    id: 'step_6_success.contract',
  },
})

const MidsContainer = ({ merchantGroup, salesforceLeadId }: MidProps): JSX.Element => {
  const { formatMessage } = useIntl()

  const [mids, setMids] = useState<string[]>([])
  const [pristineMids, setPristineMids] = useState<string[]>([])
  const [failedMids, setFailedMids] = useState<string[]>([])

  useEffect(() => {
    const mids = merchantGroup?.merchants?.[0]?.merchant_ids || []
    setMids(mids)
    setPristineMids(mids)
  }, [merchantGroup])

  const handleResponseMID = (mid: string): void => {
    setMids(mids.concat(mid))
  }

  const handleErrorMID = (error: Error, mid: string): void => {
    setFailedMids(mids.concat(mid))
    Sentry.withScope((scope) => {
      scope.setContext('/Home/Step6Success#addMid', {
        mids,
        mid,
      })
      Sentry.captureException(error)
    })
  }

  const handleAddMID = (mid: string): void => {
    if (merchantGroup?.merchants?.[0]?.id) {
      addMid(merchantGroup.merchants[0].id, [mid], merchantGroup.salesforce_account_id)
        .then(() => handleResponseMID(mid))
        .catch((error) => handleErrorMID(error, mid))
    } else if (salesforceLeadId) {
      addLeadPaymentTerminalKeys(salesforceLeadId, [mid])
        .then(() => handleResponseMID(mid))
        .catch((error) => handleErrorMID(error, mid))
    } else {
      Sentry.withScope((scope) => {
        scope.setContext('/Home/Step6Success#addMid', {
          mids,
          mid,
        })
        Sentry.captureException('no merchant_id and no salesforceLeadId')
      })
    }
  }

  return (
    <>
      <Mids ids={mids} originalIds={pristineMids} failedIds={failedMids} onAdd={handleAddMID} />
      <QualificationText>
        <FormattedMessage id="step_6_success.new_terminal" values={{ b: (chunks: string) => <b>{chunks}</b> }} />
      </QualificationText>
      <ButtonsWrapper>
        <Button component="a" variant="primary" href={redirectWithLocale(config.websiteUrl)}>
          {formatMessage(messages.allGood)}
        </Button>
      </ButtonsWrapper>
    </>
  )
}

export function Step6Success(): JSX.Element {
  const { formatMessage } = useIntl()

  const { merchantGroupInfo, userContact, salesforceInfo } = useOnboardingState()

  return (
    <TunnelStepWrapper data-testid="step-6-success">
      <TunnelFormWrapper>
        {gtag.GA_TRACKING_ID && gtag.GA_DATALAYER_ID && (
          <script
            dangerouslySetInnerHTML={{
              __html: `	
            <!-- Event snippet for Tunnel resto (Google ads) conversion page -->
              gtag('event', 'conversion', {'send_to': '${gtag.GA_DATALAYER_ID}/C0EoCIXvkNUBEMe1r5gD'});
            `,
            }}
          />
        )}
        <QualificationTitle>
          {formatMessage(messages.affiliationValidated, {
            firstName: userContact.firstName,
            br: <br />,
          })}
        </QualificationTitle>
        <QualificationText>{formatMessage(messages.welcome)}</QualificationText>
        <QualificationText>{formatMessage(messages.contract)}</QualificationText>
        <MidsContainer salesforceLeadId={salesforceInfo.account?.sfid} merchantGroup={merchantGroupInfo} />
      </TunnelFormWrapper>
    </TunnelStepWrapper>
  )
}
