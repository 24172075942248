import axios, { AxiosResponse } from 'axios'

import { Company } from '@tunnel-types/globals'

interface Response {
  companies: Company[]
  currentPage: number
  totalPages: number
}

export const getSocieteInfoData = async (companyName: string, page = 1): Promise<AxiosResponse<Response>> => {
  return await axios.get<Response>('/api/societe-info', {
    params: {
      companyName,
      page,
    },
  })
}
