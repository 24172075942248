import axios from 'axios'

import { isAxiosError } from '@shared/errors/is-axios-error'
import { Company } from '@tunnel-types/globals'

interface Response {
  company: Company
}

async function searchCompanyInfo(query: Record<string, string>): Promise<Company | null> {
  try {
    const { data } = await axios.get<Response>('/api/company', { params: query })
    return data.company
  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 404) {
      return null
    }
    throw error
  }
}

export async function getCompanyInfoBySiret(siretCode: string): Promise<Company | null> {
  return await searchCompanyInfo({ siretCode })
}

export async function getCompanyInfoByName(name: string, zip: string): Promise<Company | null> {
  return await searchCompanyInfo({ name, zip })
}
