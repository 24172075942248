import styled from 'styled-components'

import { COLORS, Button, Typography } from '@themenu/design-system'

import { breakpoints } from '@shared/utils'

export const BodyWrapper = styled.div`
  height: 100vh;
  background: url(/images/finalSteps/multiple-ellipse-background-small.png) no-repeat center center fixed, black;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Message = styled.div`
  background-color: ${COLORS.WHITE['200']};
  color: ${COLORS.GREEN['900']};
  min-height: 347px;
  padding: 20px;
  width: 510px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${breakpoints.down('xs')} {
    width: 100%;
  }
`

export const LogoWrapper = styled.div`
  position: absolute;
  top: 30px;
  left: 30px;
`

export const ButtonBlackPurple = styled(({ ...props }) => <Button size={40} {...props} />)`
  background: ${COLORS.PURPLE['900']};
  border: 1px solid ${COLORS.PURPLE['900']};
  border-radius: 20px;
  width: fit-content;
  :hover {
    background: ${COLORS.PURPLE['400']};
  }
`

export const StyledTypography = styled(Typography)`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 1rem;
`
