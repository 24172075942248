import { useIntl } from 'react-intl'

import { COLORS, Typography } from '@themenu/design-system'

import { convertYupIntlMessage, YupIntlMesssage } from '@i18n/yup'

type Props = {
  message: YupIntlMesssage | string
}

export function ErrorMessage({ message }: Props): JSX.Element | null {
  const intl = useIntl()

  const formattedMessage = message ? convertYupIntlMessage(intl, message) : undefined

  if (!formattedMessage) {
    return null
  }

  return (
    <Typography variant="footnote" color={COLORS.PEACH['600']}>
      {formattedMessage}
    </Typography>
  )
}
