import { UserContact, CompanyInfo } from '@src/store/onboarding/OnboardingState'
import { Prospect, AddressInformation, CntrMerchant, Company, SalesforceLeadProps } from '@tunnel-types/globals'

export type LocalMerchant = {
  immatriculation: string
  cntrAgreementNumber?: string
  companyName: string
  companyLegalName?: string
  street: string | null
  zip: string
  city: string
  countryCode: string
}

export const cntrToLocalMerchant = (data: CntrMerchant): LocalMerchant => {
  return {
    immatriculation: data.siret.toString(),
    cntrAgreementNumber: data.cntr_agreement_number,
    companyName: data.merchant_name,
    companyLegalName: data.legal_name,
    street: data.street,
    zip: data.zip,
    city: data.city,
    countryCode: data.country_code,
  }
}

export const getAddressInfoFromCompany = (company: Company): AddressInformation => {
  const { billingAddress } = company
  return {
    address: billingAddress?.street || '',
    additionalAddress: billingAddress?.street2 || '',
    postalCode: billingAddress?.zip || '',
    city: billingAddress?.city || '',
    country: billingAddress?.country || '',
  }
}

export const formatCompanyToMerchant = (company: Company): LocalMerchant => ({
  city: company.billingAddress?.city || '',
  companyName: company.name,
  countryCode: company.billingAddress?.country || '',
  immatriculation: company.siret || '',
  street: company.billingAddress?.street || '',
  zip: company.billingAddress?.zip || '',
})

export const formatFormToSalesforceLead = (
  userInfo: UserContact,
  companyInfo: Company,
  merchant: LocalMerchant,
  prospect: Prospect
): SalesforceLeadProps => ({
  immatriculation: companyInfo.siret || '',
  merchants_count: companyInfo.locationsNumber || '1',
  first_name: userInfo.firstName,
  last_name: userInfo.lastName,
  email: userInfo.email,
  phone: userInfo.phone,
  company_name: merchant.companyName || 'nom inconnu',
  company_legal_name: merchant.companyLegalName || 'nom inconnu',
  street: merchant.street || '',
  zip: merchant.zip,
  city: merchant.city,
  country_code: merchant.countryCode,
  source_category: prospect.category || 'Organic',
  source_detail: prospect.detail || 'Accès Direct',
})

export const getCompanyInfoFromCompany = (
  company: Company
): Pick<CompanyInfo, 'name' | 'cityofRegistration' | 'siret'> => {
  return {
    name: company.name || '',
    cityofRegistration: company.city || '',
    siret: company.siret || '',
  }
}
