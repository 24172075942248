import React, { useState, ChangeEvent, FunctionComponent } from 'react'

import { defineMessages, useIntl } from 'react-intl'
import styled from 'styled-components'

import { Box, Button, Typography, COLORS } from '@themenu/design-system'

import Sentry from '@lib/sentry'
import { getCompanyInfoBySiret } from '@services/client/company'
import { FormSearchInput, ListItem } from '@shared/components'
import { Company } from '@tunnel-types/globals'

const SIRET_NUMBER_LENGTH = 14

const messages = defineMessages({
  siretPlaceholder: {
    id: 'step_2_company_info.search_by_siret.input.placeholder',
  },
  backButton: {
    id: 'step_2_company_info.search_by_siret.back_button',
  },
  listItem: {
    id: 'step_2_company_info.search_by_siret.list_item',
  },
  companySiretNotfoundError: {
    id: 'step_2_company_info.search_by_siret.error_not_found',
  },
  searchIconAltText: {
    id: 'step_2_company_info.input.search_icon.alt_text',
  },
})

const StyledButton = styled(({ children, ...props }) => <Button {...props}>{children}</Button>)`
  width: 150px;
`

interface Props {
  handleOnSelect: (company: Company) => void
  onViewChange: () => void
}

const SearchBySiret: FunctionComponent<Props> = ({ handleOnSelect, onViewChange }) => {
  const { formatMessage } = useIntl()
  const [companiesList, setCompaniesList] = useState<Company[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isSearchByError, setIsSearchByError] = useState<boolean>(false)

  const handleOnSearch = async (e: ChangeEvent<HTMLInputElement>): Promise<void> => {
    const { value } = e.target
    const siretNumber = value.replace(/\D/gm, '')

    setIsSearchByError(false)
    setIsLoading(true)

    if (siretNumber.length === SIRET_NUMBER_LENGTH) {
      try {
        const company = await getCompanyInfoBySiret(siretNumber)

        if (company) {
          setCompaniesList([company])
        } else {
          setIsSearchByError(true)
          setCompaniesList([])
        }
      } catch (err) {
        setIsSearchByError(true)

        Sentry.withScope((scope) => {
          scope.setContext('SearchBySiret#handleOnSearch', { siret: siretNumber })
          Sentry.captureException(err)
        })
      }
    }

    setIsLoading(false)
  }

  const handleOnSelectedCompany = async (selectedCompany: Company): Promise<void> => {
    setIsLoading(true)
    handleOnSelect(selectedCompany)
    setIsLoading(false)
  }

  return (
    <>
      <Box>
        <FormSearchInput
          id="searchBySiretNumber"
          searchIcon={{
            altText: formatMessage(messages.searchIconAltText),
            src: '/images/drawer/search.svg',
          }}
          isLoading={isLoading}
          onChange={handleOnSearch}
          placeholder={formatMessage(messages.siretPlaceholder)}
        />
        {isSearchByError && (
          <Box mt={16}>
            <Typography color={COLORS.PEACH['200']} weight="medium" variant="body">
              {formatMessage(messages.companySiretNotfoundError)}
            </Typography>
          </Box>
        )}
        <Box mt={20}>
          {companiesList.map((company: Company, index: number) => (
            <ListItem
              key={index}
              firstLineText={company.formattedAddress}
              onClick={() => handleOnSelectedCompany(company)}
              secondLineText={
                company.siret &&
                formatMessage(messages.listItem, {
                  siret: company.siret,
                })
              }
              title={company.name}
            />
          ))}
        </Box>
      </Box>
      <Box display="flex" justifyContent="center">
        <StyledButton onClick={onViewChange} variant="secondary">
          {formatMessage(messages.backButton)}
        </StyledButton>
      </Box>
    </>
  )
}

export default SearchBySiret
