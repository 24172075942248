import React, { useState, useEffect, KeyboardEvent, ChangeEvent, SetStateAction } from 'react'

import { useIntl, defineMessages } from 'react-intl'

import { Button } from '@themenu/design-system'

import { isSuspiciousMid } from './Mids.model'
import { AddMidSection, GlobalStyle, MidsContainer, MidsMainText, MidsMainTitle } from './Mids.style'
import AddTerminalsModalHint from '@components/AddTerminalsHint/AddTerminalsHint'
import Mid from '@components/Mid/Mid'

interface MidsProps {
  ids: string[]
  originalIds: string[]
  failedIds?: string[]
  onAdd: (value: string) => void
}

const messages = defineMessages({
  title: {
    id: 'step_6_success.mids.title',
  },
  midsCount: {
    id: 'step_6_success.mids.mids_count',
  },
  addMid: {
    id: 'step_6_success.mids.add_mid',
  },
})

const Mids = ({ ids, originalIds, failedIds = [], onAdd }: MidsProps): JSX.Element => {
  const [tmpIds, setTmpIds] = useState<string[]>([])

  const { formatMessage } = useIntl()

  useEffect(() => setTmpIds(tmpIds.filter((elt) => !ids.includes(elt))), [ids])

  const isIdIncluded = (id: string, list: string[]): boolean => list.includes(id)

  const handleNewMid = (): void => setTmpIds(tmpIds.concat(''))

  const removeTmpId = (index: number) => () => {
    setTmpIds(tmpIds.filter((elt, idx) => idx !== index))
  }

  const addId = (index: number): void => {
    const id = tmpIds[index]
    if (id.length === 0 || isIdIncluded(id, failedIds)) {
      return
    }

    isIdIncluded(id, ids) ? setTmpIds(tmpIds.filter((elt) => elt !== id)) : onAdd(id)
  }

  const handleChange = (index: number) => (event: ChangeEvent<HTMLInputElement>) => {
    setTmpIds(tmpIds.map((tmpId, tmpIdIndex) => (index === tmpIdIndex ? event.target.value : tmpId)))
  }

  const handleBlur = (index: number): ((value: SetStateAction<string[]>) => void) => {
    return () => tmpIds[index] === '' && removeTmpId(index)
  }

  const handleKeyDown = (index: number) => (event: KeyboardEvent<HTMLDivElement>) => {
    const { key } = event
    switch (key) {
      case 'Escape':
        removeTmpId(index)
        break
      case 'Enter':
        addId(index)
        break
      default:
    }
  }

  return (
    <>
      <MidsContainer>
        <GlobalStyle />
        <div>
          <MidsMainTitle>{formatMessage(messages.title)}</MidsMainTitle>
        </div>
        {originalIds.length > 0 && (
          <div>
            <MidsMainText>
              {formatMessage(messages.midsCount, {
                midsCount: ids.length,
              })}
            </MidsMainText>
          </div>
        )}
        {ids.map((mid, key) => (
          <Mid key={key} midId={mid} disabled={true} />
        ))}
        {tmpIds.map((mid, key) => (
          <Mid
            key={key}
            midId={mid}
            disabled={false}
            error={isIdIncluded(mid, failedIds)}
            warn={isSuspiciousMid(mid)}
            onRemove={removeTmpId(key)}
            onKeyDown={handleKeyDown(key)}
            onBlur={() => handleBlur(key)}
            onChange={handleChange(key)}
            onAccept={() => addId(key)}
          />
        ))}
        <AddMidSection>
          <Button onClick={handleNewMid}>{formatMessage(messages.addMid)}</Button>
        </AddMidSection>
      </MidsContainer>
      <AddTerminalsModalHint />
    </>
  )
}

export default Mids
