import React from 'react'

import styled from 'styled-components'

import { COLORS, Typography } from '@themenu/design-system'

export const ButtonsWrapper = styled.div`
  margin-top: 73px;
  display: flex;
  justify-content: center;
`

export const QualificationText = styled((props) => <Typography variant="h6" {...props} />)`
  color: ${COLORS.PURPLE['900']};
  font-size: 15px;
`

export const QualificationTitle = styled((props) => <Typography variant="h1" {...props} />)`
  font-size: 30px;
  color: ${COLORS.PURPLE['900']};
  margin-bottom: 26px;
  font-weight: 400;
  line-height: 40px;
`
