import { useRouter } from 'next/router'

import { Form, Formik, FormikHelpers, FormikProps } from 'formik'
import { defineMessages, useIntl } from 'react-intl'
import styled from 'styled-components'

import { Box, Button, COLORS, Typography } from '@themenu/design-system'

import { isAffiliatesLead } from '../Step2CompanyInfo/helpers'
import { formFieldsValidation } from '../Step4BankingInfo/Step4BankingInfo.validation'
import { Step } from '@common/constants'
import { updateStepRouteQueryParams } from '@common/utils/updateStepRouteQueryParams'
import { TextInput } from '@components/index'
import SalesAvatarDialogue from '@components/SalesAvatarDialogue/SalesAvatarDialogue'
import { intlCommonForm } from '@i18n/intlMessages'
import Sentry from '@lib/sentry'
import { updateAccount, updateLead } from '@services/client/affiliates'
import { submitBankInfo } from '@services/client/pardot'
import { FormColumn, FormGrid, TunnelFormWrapper, TunnelStepWrapper } from '@shared/components'
import breakpoints from '@shared/utils/breakpoints'
import { ActionType, useDispatchOnboardingAction, useOnboardingState } from '@src/store'
import { AccountOrLeadInfo, Address, BillingInfo } from '@tunnel-types/globals'

const messages = defineMessages({
  dialogueMessage: {
    id: 'step_4_banking_info.avatar_dialogue.message',
  },
  ibanLabel: {
    id: 'step_4_banking_info.form.iban.label',
  },
  ibanPlaceholder: {
    id: 'step_4_banking_info.form.iban.placeholder',
  },
  bicLabel: {
    id: 'step_4_banking_info.form.bic.label',
  },
  bicPlaceholder: {
    id: 'step_4_banking_info.form.bic.placeholder',
  },
  contactDetailsTitle: {
    id: 'step_4_banking_info.contact_details.title',
  },
  creditorDetailsTitle: {
    id: 'step_4_banking_info.creditor_details.title',
  },
  paymentDetailsTitle: {
    id: 'step_4_banking_info.payment_details.title',
  },
  paymentDetailsBody: {
    id: 'step_4_banking_info.payment_details.body',
  },
  nonEmpty: {
    id: 'step_4_banking_info.non_empty',
  },
  invalidIban: {
    id: 'step_4_banking_info.invalid_iban',
  },
  invalidBic: {
    id: 'step_4_banking_info.invalid_bic',
  },
})

const UserInfoContainer = styled(Box)`
  display: flex;
  flex-direction: column;

  border-right: 1px solid ${COLORS.WHITE['300']};

  ${breakpoints.down('sm')} {
    border-bottom: 1px solid ${COLORS.WHITE['300']};
  }
`

const BoxTop = styled(Box)`
  border-bottom: 1px solid ${COLORS.WHITE['300']};
  ${breakpoints.down('sm')} {
    flex-direction: column;
  }
`

interface FormValues {
  iban: string
  bic: string
}

export function Step4BankingInfo(): JSX.Element {
  const { formatMessage } = useIntl()
  const router = useRouter()

  const { salesforceInfo } = useOnboardingState()
  const dispatchOnboardingAction = useDispatchOnboardingAction()

  const { name, billing_address, contact_person } = salesforceInfo.account as AccountOrLeadInfo

  const handleSubmit = async (values: FormValues, formikHelpers: FormikHelpers<FormValues>): Promise<void> => {
    const billingInfo: BillingInfo = {
      iban: values.iban,
      bic: values.bic,
    }

    const updatedAccount: AccountOrLeadInfo = {
      ...(salesforceInfo.account as AccountOrLeadInfo),
      billing_info: billingInfo,
    }

    try {
      await submitBankInfo(contact_person, billingInfo)

      if (isAffiliatesLead(updatedAccount)) {
        await updateLead(updatedAccount)
      } else {
        await updateAccount(updatedAccount)
        dispatchOnboardingAction({
          type: ActionType.UPDATE_SALESFORCE_INFO,
          payload: {
            account: {
              ...(salesforceInfo.account as AccountOrLeadInfo),
              billing_info: {
                ...(salesforceInfo.account as AccountOrLeadInfo).billing_info,
                iban: billingInfo.iban,
                bic: billingInfo.bic,
              },
            },
            owner: salesforceInfo.owner,
          },
        })
      }

      updateStepRouteQueryParams(router, Step.SIGNATURE)
    } catch (err) {
      Sentry.withScope((scope) => {
        scope.setContext('/Home/Step4BankingInfo#submitBankInfo', {
          ...values,
        })
        Sentry.captureException(err)
      })
      formikHelpers.setSubmitting(false)
    }
  }

  const formatCity = (address: Address): string => {
    const { zip, city } = address
    return `${zip} ${city}`
  }

  const validationSchema = formFieldsValidation(
    formatMessage(messages.nonEmpty),
    formatMessage(messages.invalidIban),
    formatMessage(messages.invalidBic)
  )

  return (
    <TunnelStepWrapper data-testid="step-4-banking-info">
      <SalesAvatarDialogue message={formatMessage(messages.dialogueMessage)} />
      <TunnelFormWrapper>
        <Formik initialValues={{ iban: '', bic: '' }} validationSchema={validationSchema} onSubmit={handleSubmit}>
          {(formik: FormikProps<FormValues>) => {
            return (
              <Form>
                <Box mb={24}>
                  <FormGrid>
                    <FormColumn>
                      <TextInput
                        id="iban"
                        name="iban"
                        label={formatMessage(messages.ibanLabel)}
                        placeholder={formatMessage(messages.ibanPlaceholder)}
                        inputProps={{
                          type: 'text',
                        }}
                      ></TextInput>
                    </FormColumn>
                    <FormColumn>
                      <TextInput
                        id="bic"
                        name="bic"
                        label={formatMessage(messages.bicLabel)}
                        placeholder={formatMessage(messages.bicPlaceholder)}
                        inputProps={{
                          type: 'text',
                        }}
                      ></TextInput>
                    </FormColumn>
                  </FormGrid>
                </Box>
                <Box mb={24} borderRadius={24} display="flex" flexDirection="column" withBorder>
                  <BoxTop flexDirection="row" display="flex">
                    <UserInfoContainer p={32} flex="1">
                      <Typography variant="body" mb={12} weight="medium">
                        {formatMessage(messages.contactDetailsTitle)}
                      </Typography>
                      <Typography variant="body">{name}</Typography>
                      <Typography variant="body">{billing_address.street}</Typography>
                      {billing_address.street2 && <Typography variant="body">{billing_address.street2}</Typography>}
                      <Typography variant="body">{formatCity(billing_address)}</Typography>
                    </UserInfoContainer>
                    <Box p={32} flex="1">
                      <Typography variant="body" mb={12} weight="medium">
                        {formatMessage(messages.creditorDetailsTitle)}
                      </Typography>
                      <Typography variant="body">Swile</Typography>
                      <Typography variant="body">561 RUE GEORGES MELIES</Typography>
                      <Typography variant="body">34000 Montpellier</Typography>
                    </Box>
                  </BoxTop>
                  <Box p={32}>
                    <Typography variant="body" mb={12} weight="medium">
                      {formatMessage(messages.paymentDetailsTitle)}
                    </Typography>
                    <Typography variant="body">{formatMessage(messages.paymentDetailsBody)}</Typography>
                  </Box>
                </Box>
                <Box>
                  <Button
                    mt={8}
                    type="submit"
                    loading={formik.isSubmitting}
                    disabled={formik.values.iban === '' || formik.values.bic === '' || !formik.isValid}
                  >
                    {formatMessage(intlCommonForm.submit)}
                  </Button>
                </Box>
              </Form>
            )
          }}
        </Formik>
      </TunnelFormWrapper>
    </TunnelStepWrapper>
  )
}
