import axios from 'axios'

import { SalesforceOwner } from '@tunnel-types/globals'

interface Data {
  sfid: string
}

export const getSalesforceAccountOwner = async (account: Data): Promise<SalesforceOwner> => {
  const { data } = await axios.get('api/salesforce/account-owner', {
    params: {
      sfid: account.sfid,
    },
  })

  return data !== '' ? data : null
}

export const getSalesforceLeadOwner = async (lead: Data): Promise<SalesforceOwner> => {
  const { data } = await axios.get('api/salesforce/lead-owner', {
    params: {
      sfid: lead.sfid,
    },
  })

  return data !== '' ? data : null
}
