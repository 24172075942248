import { defineMessages } from 'react-intl'

export const Step5SignatureMessages = defineMessages({
  pageTitle: {
    id: 'step_5_signature.avatar_dialogue.message',
    defaultMessage: 'In order to validate your affiliation contract, select a signature font or draw your signature',
  },
  submitButton: {
    id: 'step_5_signature.accept_and_sign',
    defaultMessage: 'Accept and sign',
  },
  legalText: {
    id: 'step_5_signature.legal_text',
    defaultMessage:
      'By signing this document with an electronic signature, I accept that this signature has the same legal validity as a handwritten signature.',
  },
  textSignatureLabel: {
    id: 'step_5_signature.signature_label',
    defaultMessage: 'Text of the signature',
  },
  textSignaturePlaceholder: {
    id: 'step_5_signature.signature_placeholder',
    defaultMessage: 'Enter your signature',
  },
  customSignatureLabel: {
    id: 'step_5_signature.signature_personnalisation',
    defaultMessage: 'Personalized signature',
  },
  customSignaturePlaceholder: {
    id: 'step_5_signature.draw_signature',
    defaultMessage: 'Select and draw directly in this area with your mouse',
  },
  customSignatureReinitialize: {
    id: 'step_5_signature.signature_reinitialize',
    defaultMessage: 'Reinitialize',
  },
  signatureRequired: {
    id: 'step_5_signature.signature_required',
    defaultMessage: 'Your signature is required to continue',
  },
  termsOfService: {
    id: 'step_5_signature.terms_of_service',
    defaultMessage: "I accept <a>Swile's Terms and Conditions of Service</a>.",
  },
  termsOfServiceRequired: {
    id: 'step_5_signature.terms_of_service_required',
    defaultMessage: 'Please accept the terms and condition',
  },
})
