import { FunctionComponent, ReactElement } from 'react'

import { Checkbox } from '@material-ui/core'
import { CheckboxProps } from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { useField } from 'formik'
import styled from 'styled-components'

import { COLORS } from '@themenu/design-system'

import { FormErrorMessage } from './FormErrorMessage'

const StyledCheckbox = styled(Checkbox)`
  color: ${COLORS.WHITE['900']};
  & *[class*='MuiSvgIcon-root'] {
    color: ${COLORS.WHITE['900']};
  }
`

type Props = CheckboxProps & {
  name: string
  label: ReactElement
}

export const CheckedIcon = (): ReactElement => {
  return (
    <svg width="18" height="18" viewBox="-1 -1 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 0C2.4 0 0 2 0 8C0 14 2.4 16 8 16C13.6 16 16 14 16 8C16 2 13.6 0 8 0Z"
        fill="#633FD3"
      />
      <path
        d="M0.5 8C0.5 5.04775 1.09385 3.21879 2.24655 2.11042C3.40359 0.997885 5.23775 0.5 8 0.5C10.7622 0.5 12.5964 0.997885 13.7534 2.11042C14.9062 3.21879 15.5 5.04775 15.5 8C15.5 10.9523 14.9062 12.7812 13.7534 13.8896C12.5964 15.0021 10.7622 15.5 8 15.5C5.23775 15.5 3.40359 15.0021 2.24655 13.8896C1.09385 12.7812 0.5 10.9523 0.5 8Z"
        stroke="black"
        strokeOpacity="0.06"
      />
      <path
        d="M9.5 3L4.6875 9L2.5 7.07143"
        stroke="white"
        strokeWidth="1.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        transform="translate(2,2)"
      />
    </svg>
  )
}

export const UncheckedIcon = (): ReactElement => {
  return (
    <svg width="18" height="18" viewBox="-1 -1 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.5 8C0.5 5.04775 1.09385 3.21879 2.24655 2.11042C3.40359 0.997885 5.23775 0.5 8 0.5C10.7622 0.5 12.5964 0.997885 13.7534 2.11042C14.9062 3.21879 15.5 5.04775 15.5 8C15.5 10.9523 14.9062 12.7812 13.7534 13.8896C12.5964 15.0021 10.7622 15.5 8 15.5C5.23775 15.5 3.40359 15.0021 2.24655 13.8896C1.09385 12.7812 0.5 10.9523 0.5 8Z"
        stroke="#633FD3"
        strokeWidth="2"
      />
    </svg>
  )
}

const CheckboxInput: FunctionComponent<Props> = ({ name, label }) => {
  const [{ value, ...field }] = useField(name)

  return (
    <div>
      <FormControlLabel
        control={
          <StyledCheckbox id={name} {...field} checked={value} icon={<UncheckedIcon />} checkedIcon={<CheckedIcon />} />
        }
        label={label}
      />
      <FormErrorMessage fieldName={name} />
    </div>
  )
}

export default CheckboxInput
