import { omitBy, isNil } from 'lodash'
import Cookie from 'universal-cookie'

import { config } from '../../config/client'

interface UtmParams {
  utm_source?: string
  utm_medium?: string
  utm_campaign?: string
  utm_term?: string
  utm_content?: string
}

interface AdditionalParams {
  organic_origin?: string
  client_id?: string
}

export type TrackingParams = UtmParams & AdditionalParams

export const trackingParamsSessionStorageKey = 'tracking-params'

const COOKIE_LIFETIME = 30
const ONE_DAY_IN_MS = 24 * 60 * 60 * 1000
const SWILE_DOMAIN = config.swileDomain

const getGoogleAnalyticsClientID = (): string | undefined => {
  if (typeof window !== 'undefined') {
    const cookie = new Cookie()
    const _ga = cookie.get('_ga')

    const gaObj = window[window.GoogleAnalyticsObject] as UniversalAnalytics.ga

    if (gaObj?.getAll) {
      const tracker = gaObj.getAll()[0]

      if (tracker) {
        return tracker.get('clientId')
      }
    }

    if (typeof _ga === 'string' && _ga.length > 0) {
      const cidArr = _ga.split('.')
      return cidArr.slice(cidArr.length - 2, cidArr.length).join('.')
    }
  }
}

export const parseUtmQueryParams = (queryString: string): UtmParams | void => {
  if (queryString) {
    const urlParams = new URLSearchParams(queryString)

    const utmParams = {
      utm_source: urlParams.get('utm_source'),
      utm_medium: urlParams.get('utm_medium'),
      utm_campaign: urlParams.get('utm_campaign'),
      utm_term: urlParams.get('utm_term'),
      utm_content: urlParams.get('utm_content'),
    }

    return omitBy(utmParams, isNil)
  }
}

export const getTrackingParams = (): TrackingParams => {
  if (typeof window !== 'undefined') {
    const cookie = new Cookie()
    const rawParams = (cookie.get(trackingParamsSessionStorageKey) as TrackingParams) || {}
    const clientId = getGoogleAnalyticsClientID()

    return { ...rawParams, client_id: clientId }
  }
  return {}
}

export const getAndSaveTrackingParams = (): void => {
  if (typeof window !== 'undefined') {
    const cookie = new Cookie()
    const expirationDate = new Date()

    expirationDate.setTime(expirationDate.getTime() + COOKIE_LIFETIME * ONE_DAY_IN_MS)

    const savedTrackingParams = getTrackingParams()

    let referrer = ''

    if (!savedTrackingParams.organic_origin) {
      if (!document.referrer.includes(SWILE_DOMAIN) && document.referrer !== '') {
        referrer = document.referrer
      }
    } else {
      if (document.referrer.includes(SWILE_DOMAIN) || document.referrer === '') {
        referrer = savedTrackingParams.organic_origin
      } else {
        referrer = document.referrer
      }
    }

    const params = JSON.stringify({
      ...savedTrackingParams,
      ...parseUtmQueryParams(window.location.search),
      organic_origin: referrer,
    })
    cookie.set(trackingParamsSessionStorageKey, params, {
      path: '/',
      domain: `.${SWILE_DOMAIN}`,
    })
  }
}
