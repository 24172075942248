import React, { RefObject, useEffect } from 'react'

import { Radio } from '@material-ui/core'
import FontFaceObserver from 'fontfaceobserver'
import { FormattedMessage } from 'react-intl'
import SignaturePad from 'react-signature-canvas'

import { Step5SignatureMessages } from '../messages'
import {
  ClearButton,
  ClearButtonWrapper,
  CustomFormControlLabel,
  SignatureBox,
  SignatureBoxCanvas,
  SignatureFormSubTitle,
  SignaturePadHelper,
  Wrapper,
} from './RadioCanvasSignature.style'

interface RadioCanvasSignatureProps {
  name?: string
  font?: string
  signature?: string
  isChecked?: boolean
  isHandWritten?: boolean
  onSignatureCheck: (signature: string) => void
  onClick?: () => void
  shouldClear?: boolean
  refs?: RefObject<CustomCanvas>
  padRef?: RefObject<CustomCanvas>
}
interface CustomCanvas extends HTMLCanvasElement {
  clear: () => void
}

/** Ensure a full 'click' event on mousedown to force 'checked' status even if mouseup is performed outside of canvas */
const ensureCheckedOnClick: Partial<React.DOMAttributes<HTMLElement>> = {
  onMouseDown: (event) => event.currentTarget.click(),
}

const RadioCanvasSignature: React.FunctionComponent<RadioCanvasSignatureProps> = ({
  name,
  font,
  signature,
  isHandWritten,
  onSignatureCheck,
  onClick,
  refs,
  padRef,
}) => {
  const onChange = (isHandWritten: boolean): void => {
    onClick?.()
    const canvas = refs?.current
    const padCanvas = padRef?.current
    if (padCanvas && isHandWritten) {
      onSignatureCheck(padCanvas.toDataURL())
    } else if (canvas) {
      padCanvas?.clear()
      onSignatureCheck(canvas.toDataURL())
    }
  }

  const resetSignature = (): void => {
    const canvas = padRef?.current
    if (onSignatureCheck && canvas) {
      canvas.clear()
      onSignatureCheck('')
    }
  }

  const RadioSignature = (isChecked: boolean): JSX.Element => (
    <Wrapper>
      <SignatureBox className={isChecked ? 'checked' : undefined} role="checkbox">
        <SignatureBoxCanvas ref={refs} />
      </SignatureBox>
    </Wrapper>
  )

  const CustomRadioSignature = (isChecked: boolean): JSX.Element => {
    return (
      <Wrapper>
        <SignatureFormSubTitle>
          <FormattedMessage {...Step5SignatureMessages.customSignatureLabel} />
        </SignatureFormSubTitle>

        <SignatureBox
          className={isChecked ? 'checked' : undefined}
          isHandWritten
          role="checkbox"
          data-testid="step-5-signature-pad-drawer"
        >
          <SignaturePad ref={padRef} onEnd={() => onChange(true)} />

          {isHandWritten && !isChecked ? (
            <SignaturePadHelper data-testid="step-5-signature-pad-helper">
              <FormattedMessage {...Step5SignatureMessages.customSignaturePlaceholder} />
            </SignaturePadHelper>
          ) : null}

          {isChecked ? (
            <ClearButtonWrapper>
              <ClearButton onClick={resetSignature}>
                <FormattedMessage {...Step5SignatureMessages.customSignatureReinitialize} />
              </ClearButton>
            </ClearButtonWrapper>
          ) : null}
        </SignatureBox>
      </Wrapper>
    )
  }

  useEffect(() => {
    font &&
      new FontFaceObserver(font).load().then(() => {
        if (!isHandWritten) {
          const canvas = refs?.current
          const ctx = canvas?.getContext('2d')
          if (ctx) {
            ctx.clearRect(0, 0, 2000, 2000)
            ctx.font = `normal normal normal 48px ${font}`
            ctx.fillText(signature || '', 14, 80, 254)
          }
        }
      })
  }, [isHandWritten, font, refs, signature])

  return (
    <CustomFormControlLabel
      value={name}
      label=""
      control={
        <Radio
          disableRipple
          color="default"
          checkedIcon={isHandWritten ? CustomRadioSignature(true) : RadioSignature(true)}
          icon={isHandWritten ? CustomRadioSignature(false) : RadioSignature(false)}
          onChange={isHandWritten ? undefined : () => onChange(false)}
          {...ensureCheckedOnClick}
        />
      }
    />
  )
}

export default RadioCanvasSignature
