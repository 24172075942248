// Only numbers, 7, 8 or 11 times for standard MIDs
const STANDARD_PATTERN = /(?=(^.{7}$)|(^.{8}$)|(^.{11}$))([0-9]+)/

// Numbers AND letters, at least one of each for Paypal / Stripe MIDs, with 13 alphanumeric characters for Paypal and 15 for Stripe
const ONLINE_PATTERN = /(?=(^.{13}$)|(^.{15}$))([0-9]+[a-zA-Z]+|[a-zA-Z]+[0-9]+)/

// Only numbers for MIDs coming from SIRET numbers
const SIRET_PATTERN = /^[0-9]{14}$/

// Asterisk and only numbers for MIDs coming from SIRET numbers
const ASTERISK_SIRET_PATTERN = /^[*][0-9]{14}$/

const PATTERNS = [STANDARD_PATTERN, ONLINE_PATTERN, SIRET_PATTERN, ASTERISK_SIRET_PATTERN]

export function isSuspiciousMid(value: string): boolean {
  return value !== '' && PATTERNS.every((pattern) => !value.match(pattern))
}
