import React, { FunctionComponent } from 'react'

import Grid from '@material-ui/core/Grid'
import { defineMessages, useIntl } from 'react-intl'
import styled from 'styled-components'

import { COLORS, Typography } from '@themenu/design-system'

const intlTerminalMessages = defineMessages({
  title: {
    id: 'add_terminals_hint.title',
  },
  localisation: {
    id: 'add_terminals_hint.localisation',
  },
  multiple: {
    id: 'add_terminals_hint.multiple_terminals',
  },
})

const HintText = styled(Typography)`
  font-size: 15px;
  color: ${COLORS.PURPLE['900']};
  margin-top: 30px;
  font-weight: normal;
  font-family: 'Graphik';
`

const AddTerminalHint: FunctionComponent = () => {
  const intl = useIntl()

  return (
    <div style={{ zIndex: 10 }}>
      <Grid container>
        <Grid item xs={12} lg={6}>
          <Typography variant="h1" style={{ fontSize: 24 }}>
            {intl.formatMessage(intlTerminalMessages.title)}
          </Typography>
          <HintText variant="body">{intl.formatMessage(intlTerminalMessages.localisation)}</HintText>
          <HintText variant="body">{intl.formatMessage(intlTerminalMessages.multiple)}</HintText>
        </Grid>
        <Grid item xs={12} lg={6} style={{ textAlign: 'center', marginTop: 30 }}>
          <img src="https://res.cloudinary.com/hbnjrwllw/image/upload/v1/web-assets/pos-ticket.png" alt="pos-ticket" />
        </Grid>
      </Grid>
    </div>
  )
}

export default AddTerminalHint
