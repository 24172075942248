import React from 'react'

import { NextPageContext, NextPage } from 'next'

import ErrorPage from '@pages-components/ErrorPage/ErrorPage'

export type ErrorPageType = NextPage<{ statusCode: number }>

const Error: ErrorPageType = ({ statusCode }) => {
  return <ErrorPage statusCode={statusCode} />
}
Error.getInitialProps = ({ res }: NextPageContext) => {
  const statusCode = res?.statusCode || 500

  return {
    statusCode,
  }
}

export default Error
