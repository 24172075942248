import { COLORS, Typography } from '@themenu/design-system'

type Props = {
  children: React.ReactNode
  large?: boolean
}

export function LegalText({ children, large }: Props): JSX.Element {
  return (
    <Typography variant={large ? 'body' : 'footnote'} color={COLORS.WHITE['900']} display="inline">
      {children}
    </Typography>
  )
}
