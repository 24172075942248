import React, { ChangeEvent, KeyboardEvent } from 'react'

import { useIntl, defineMessages } from 'react-intl'

import { Box, Button } from '@themenu/design-system'

import { MidListItemText, MidListItem } from '../Mids/Mids.style'
import { i18n } from './Mid.i18n'
import { InputWrapper, MidSuspucious } from './Mid.style'

interface MidProps {
  midId?: string
  disabled?: boolean
  error?: boolean
  warn?: boolean
  onAccept?: () => void
  onRemove?: () => void
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  onBlur?: () => void
  onKeyDown?: (keyCode: KeyboardEvent<HTMLDivElement>) => void
}

const messages = defineMessages({
  validate: {
    id: 'common.form.confirm',
  },
  delete: {
    id: 'common.form.delete',
  },
  suspicious: {
    id: 'suspicious_mid_format',
    defaultMessage:
      'This terminal ID seems to have a wrong format. Check your credit card receipt or look for the merchant contract signed with your bank.',
  },
})
const Mid = ({
  midId,
  disabled,
  error,
  warn,
  onAccept,
  onRemove,
  onChange,
  onBlur,
  onKeyDown,
}: MidProps): JSX.Element => {
  const { formatMessage } = useIntl()

  return (
    <>
      {disabled && (
        <MidListItem className={error ? 'error' : undefined}>
          <MidListItemText>{midId}</MidListItemText>
        </MidListItem>
      )}
      {!disabled && (
        <MidListItem className={error ? 'error' : undefined}>
          <MidListItemText>
            <InputWrapper
              placeholder={formatMessage(i18n.midIdentifierPlaceholder)}
              onChange={onChange}
              onBlur={onBlur}
              onKeyDown={onKeyDown}
            />
          </MidListItemText>
          <Box>
            <Button variant="secondary" size={32} ml={8} mr={8} role="button" onClick={onAccept}>
              {formatMessage(messages.validate)}
            </Button>
            <Button variant="secondary" size={32} role="button" onClick={onRemove}>
              {formatMessage(messages.delete)}
            </Button>
            {warn && <MidSuspucious>{formatMessage(messages.suspicious)}</MidSuspucious>}
          </Box>
        </MidListItem>
      )}
    </>
  )
}
export default Mid
