import { useState } from 'react'

import { useRouter } from 'next/router'

import { Form, Formik, FormikHelpers, FormikProps } from 'formik'
import { defineMessages, useIntl } from 'react-intl'
import styled from 'styled-components'

import { Box, Button } from '@themenu/design-system'

import { findNextStep, getSalesforceInfo, getSalesforceOwner } from './helpers'
import SearchCompanyInfo from './SearchCompanyInfo/SearchCompanyInfo'
import { formFieldsValidation } from './Step2CompanyInfo.validation'
import { Step } from '@common/constants'
import {
  cntrToLocalMerchant,
  formatCompanyToMerchant,
  getAddressInfoFromCompany,
  getCompanyInfoFromCompany,
} from '@common/utils/format'
import { updateStepRouteQueryParams } from '@common/utils/updateStepRouteQueryParams'
import { Drawer, SearchCompanyButton, TextInput } from '@components/index'
import SalesAvatarDialogue from '@components/SalesAvatarDialogue/SalesAvatarDialogue'
import { intlCommonForm } from '@i18n/intlMessages'
import Sentry from '@lib/sentry'
import { getCntrMerchants } from '@services/client/affiliates'
import { submitCompanyInfo } from '@services/client/pardot'
import { TunnelFormWrapper, TunnelStepWrapper } from '@shared/components'
import { breakpoints } from '@shared/utils'
import { ActionType, useDispatchOnboardingAction, useOnboardingState } from '@src/store'
import { Company } from '@tunnel-types/globals'

const messages = defineMessages({
  companyNameLabel: {
    id: 'step_2_company_info.form.company_name.label',
  },
  locationsNumberLabel: {
    id: 'step_2_company_info.form.locations_number.label',
  },
  locationsNumberPlaceholder: {
    id: 'step_2_company_info.form.locations_number.placeholder',
  },
  dialogueMessage: {
    id: 'step_2_company_info.avatar_dialogue.message',
  },
  drawerDefaultTitle: {
    id: 'step_2_company_info.drawer.default.title',
  },
  error: {
    id: 'step_2.errors.global_error',
  },
})

const ButtonContainer = styled(Box)`
  display: flex;
  justify-content: center;

  ${breakpoints.up('sm')} {
    justify-content: flex-start;
  }
`

const TextInputContainer = styled(Box)`
  padding-right: 16px;

  ${breakpoints.up('sm')} {
    justify-content: flex-start;
    max-width: 18.5rem;
    padding: 0;
  }
`

interface FormValues {
  locationsNumber: string
}

export function Step2CompanyInfo(): JSX.Element {
  const { formatMessage } = useIntl()
  const router = useRouter()

  const { userContact } = useOnboardingState()
  const dispatchOnboardingAction = useDispatchOnboardingAction()

  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false)
  const [drawerTitle, setDrawerTitle] = useState<string>(formatMessage(messages.drawerDefaultTitle))
  const [isCompanyNotSelected, setIsCompanyNotSelected] = useState<boolean>(false)

  const [company, setCompany] = useState<Company | undefined>(undefined)

  const handleSubmit = async (values: FormValues, formikHelpers: FormikHelpers<FormValues>): Promise<void> => {
    try {
      if (!company) {
        setIsCompanyNotSelected(true)
        return
      }
      let merchant = null

      try {
        if (company.siret) {
          const merchants = await getCntrMerchants(company.siret)
          merchant = cntrToLocalMerchant(merchants)
        } else {
          merchant = formatCompanyToMerchant(company)
        }
      } catch (error) {
        merchant = formatCompanyToMerchant(company)
      }
      const nextStep = company ? await findNextStep(company, values.locationsNumber || '') : Step.COMPANY_INFO

      if (nextStep === Step.CNTR_UNAFFILIATED) {
        router.push('/cntr-unaffiliated')
      } else if (nextStep === Step.ALREADY_AFFILIATED) {
        router.push({
          pathname: '/already-affiliated',
          query: { name: company.name },
        })
      } else if (nextStep === Step.MULTIPLE_RESTAURANTS) {
        router.push('/multiple-restaurants')
      } else {
        const salesforceInfo = await getSalesforceInfo(nextStep, userContact, company, merchant)

        const owner = await getSalesforceOwner(salesforceInfo)

        dispatchOnboardingAction({
          type: ActionType.UPDATE_COMPANY_INFO,
          payload: {
            ...values,
            ...company,
            ...getCompanyInfoFromCompany(company),
            address: getAddressInfoFromCompany(company),
            owner,
          },
        })

        dispatchOnboardingAction({
          type: ActionType.UPDATE_SALESFORCE_INFO,
          payload: {
            account: {
              ...salesforceInfo,
            },
            owner,
          },
        })

        await submitCompanyInfo(userContact, merchant, company)
        updateStepRouteQueryParams(router, nextStep)
      }
    } catch (err) {
      Sentry.withScope((scope) => {
        scope.setContext('/Home/Step2CompanyInfo#submitUserContact', {
          ...values,
        })
        Sentry.captureException(err)
      })
    }
    formikHelpers.setSubmitting(false)
  }

  const handleOnSelectedCompany = (company: Company): void => {
    setCompany(company)
    setIsDrawerOpen(false)
    setIsCompanyNotSelected(false)
    setDrawerTitle(formatMessage(messages.drawerDefaultTitle))
  }

  return (
    <TunnelStepWrapper data-testid="step-2-company-info">
      <Drawer
        isOpen={isDrawerOpen}
        onClose={() => {
          setIsDrawerOpen(false)
          setDrawerTitle(formatMessage(messages.drawerDefaultTitle))
        }}
        title={drawerTitle}
      >
        <SearchCompanyInfo handleOnSelect={handleOnSelectedCompany} handleOnTitleChange={setDrawerTitle} />
      </Drawer>
      <SalesAvatarDialogue message={formatMessage(messages.dialogueMessage)} />
      <TunnelFormWrapper>
        <Formik initialValues={{ locationsNumber: '' }} validationSchema={formFieldsValidation} onSubmit={handleSubmit}>
          {(formik: FormikProps<FormValues>) => {
            return (
              <Form>
                <Box mb={24}>
                  <SearchCompanyButton
                    error={isCompanyNotSelected}
                    companyName={company?.name}
                    labelText={formatMessage(messages.companyNameLabel)}
                    onClick={() => setIsDrawerOpen(true)}
                  />
                  <TextInputContainer mt={40}>
                    <TextInput
                      id="locationsNumber"
                      name="locationsNumber"
                      label={formatMessage(messages.locationsNumberLabel)}
                      placeholder={formatMessage(messages.locationsNumberPlaceholder)}
                      inputProps={{
                        type: 'text',
                      }}
                    />
                  </TextInputContainer>
                  <ButtonContainer pr={16}>
                    <Button
                      mt={8}
                      type="submit"
                      loading={formik.isSubmitting}
                      disabled={company === undefined || formik.values.locationsNumber === ''}
                    >
                      {formatMessage(intlCommonForm.submit)}
                    </Button>
                  </ButtonContainer>
                </Box>
              </Form>
            )
          }}
        </Formik>
      </TunnelFormWrapper>
    </TunnelStepWrapper>
  )
}
