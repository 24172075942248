import { FormControlLabel } from '@material-ui/core'
import styled from 'styled-components'

import { COLORS, Typography } from '@themenu/design-system'

import { breakpoints } from '@shared/utils'

export const Wrapper = styled.div`
  z-index: 100;
  width: 100%;
`

export const CustomFormControlLabel = styled(FormControlLabel)`
  width: 100%;
  & > span {
    width: 100%;
  }
  & .MuiIconButton-root:hover {
    background: none;
  }
  & input {
    display: none;
  }
  ${breakpoints.down('xs')} {
    margin-left: 0;
    margin-right: 0;
  }
`

export const SignatureBox = styled.div<{ isHandWritten?: boolean }>`
  border: 1px solid #dddce4;
  box-sizing: border-box;
  border-radius: 16px;
  width: 100%;
  height: 80px;
  margin-bottom: 16px;
  margin-left: -11px;
  ${breakpoints.down('xs')} {
    margin-left: 0;
    margin-right: 0;
  }
  &.checked {
    border: 3px solid #1d1148;
    box-sizing: border-box;
    border-radius: 16px;
  }
  ${({ isHandWritten }) =>
    isHandWritten &&
    `
    width: 100%;
    height: 120px;
    & > div, & .m-signature-pad, & .m-signature-pad--body {
      height: 100% 
    }
    & canvas {
      width: 100%;
      height: 100%;
      max-height: 120px;
    }
  `}
`
export const SignatureBoxCanvas = styled.canvas`
  width: 100%;
  height: 100%;
  max-width: 250px;
`

export const SignaturePadHelper = styled.div`
  position: relative;
  top: -70px;
  font-size: 11px;
  margin-left: 5px;
  margin-right: 5px;
  z-index: -1;
  ${breakpoints.down('xs')} {
    top: -90px;
  }
`

export const ClearButton = styled.div`
  width: min-content;
  text-align: right;
  font-size: 14px;
  margin-top: 10px;
  &:hover {
    cursor: pointer;
  }
`

export const ClearButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  height: min-content !important;
  &:hover {
    cursor: initial;
  }
`

export const SignatureFormSubTitle = styled((props) => <Typography variant="h6" {...props} />)`
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  color: ${COLORS.WHITE['900']};
  margin-bottom: 10px;
`
