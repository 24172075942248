import React, { useState } from 'react'

import { Form, Formik, FormikProps } from 'formik'
import { defineMessages, useIntl } from 'react-intl'

import { Box, Button, Typography } from '@themenu/design-system'

import Yup from '@common/utils/yup'
import { TextInput } from '@components/index'
import { intlCommonForm } from '@i18n/intlMessages'
import Sentry from '@lib/sentry'
import { FieldName, FormColumn, FormGrid, RecapHeader, RecapWrapper, StyledPenIcon } from '@shared/components'
import { ActionType, useDispatchOnboardingAction, useOnboardingState } from '@src/store'
import { AccountOrLeadInfo, AddressInformation } from '@tunnel-types/globals'

const messages = defineMessages({
  title: {
    id: 'step_3_recap.contact_form.address',
  },
  mailingAddress: {
    id: 'step_3_recap.address_form.company_mailing_address',
  },
})

export const RecapAddressValidationFormSchema = Yup.object().shape({
  address: Yup.string().required(),
  additionalAddress: Yup.string(),
  postalCode: Yup.string().required(),
  city: Yup.string().required(),
})

export default function RecapAddressForm(): JSX.Element {
  const { formatMessage } = useIntl()
  const { companyInfo, salesforceInfo } = useOnboardingState()
  const dispatchOnboardingAction = useDispatchOnboardingAction()

  const [readOnly, setReadOnly] = useState<boolean>(RecapAddressValidationFormSchema.isValidSync(companyInfo.address))

  const onCancel = (formik: FormikProps<AddressInformation>): void => {
    formik.setValues(companyInfo.address)
    setReadOnly(true)
  }

  const onSubmit = async (values: AddressInformation): Promise<void> => {
    try {
      dispatchOnboardingAction({
        type: ActionType.UPDATE_COMPANY_INFO,
        payload: { ...companyInfo, address: values },
      })
      dispatchOnboardingAction({
        type: ActionType.UPDATE_SALESFORCE_INFO,
        payload: {
          ...salesforceInfo,
          account: {
            ...(salesforceInfo.account as AccountOrLeadInfo),
            billing_address: {
              ...(salesforceInfo.account as AccountOrLeadInfo).billing_address,
              street: values.address || (salesforceInfo.account as AccountOrLeadInfo).billing_address.street,
              street2:
                values.additionalAddress || (salesforceInfo.account as AccountOrLeadInfo).billing_address.street2,
              zip: values.postalCode || (salesforceInfo.account as AccountOrLeadInfo).billing_address.zip,
              city: values.city || (salesforceInfo.account as AccountOrLeadInfo).billing_address.city,
            },
          },
        },
      })
      setReadOnly(true)
    } catch (error) {
      Sentry.withScope((scope) => {
        scope.setContext('/Home/Step3ContractInfo/RecapAddressForm#submitUserContact', {
          ...values,
        })
        Sentry.captureException(error)
      })
    }
  }

  return (
    <Formik
      initialValues={companyInfo.address}
      validationSchema={RecapAddressValidationFormSchema}
      onSubmit={onSubmit}
      validateOnMount
      enableReinitialize
    >
      {(formik: FormikProps<AddressInformation>) => {
        return (
          <Form autoComplete="on">
            <RecapWrapper error={Object.keys(formik.errors).length !== 0}>
              <RecapHeader
                title={formatMessage(messages.title)}
                action={
                  readOnly ? (
                    <Button type="submit" variant="secondary" size={40} onClick={() => setReadOnly(false)}>
                      <StyledPenIcon />
                      {formatMessage(intlCommonForm.edit)}
                    </Button>
                  ) : undefined
                }
              />
              {readOnly && (
                <React.Fragment>
                  <FieldName>{formatMessage(messages.mailingAddress)}</FieldName>
                  <Typography variant="body">
                    {companyInfo.address.address},{' '}
                    {companyInfo.address.additionalAddress && `${companyInfo.address.additionalAddress}, `}
                    {companyInfo.address.postalCode} {companyInfo.address.city}
                  </Typography>
                </React.Fragment>
              )}
              {!readOnly && (
                <React.Fragment>
                  <FormGrid>
                    <FormColumn fullWidth>
                      <TextInput
                        id="address"
                        name="address"
                        readOnly={readOnly}
                        label={formatMessage(intlCommonForm.street)}
                        placeholder={formatMessage(intlCommonForm.streetPlaceholder)}
                        inputProps={{
                          type: 'text',
                          autoComplete: 'address-line1',
                        }}
                      />
                    </FormColumn>
                    <FormColumn fullWidth>
                      <TextInput
                        id="additionalAddress"
                        name="additionalAddress"
                        readOnly={readOnly}
                        label={formatMessage(intlCommonForm.additionalAddress)}
                        placeholder={formatMessage(intlCommonForm.additionalAddressPlaceholder)}
                        inputProps={{
                          type: 'text',
                          autoComplete: 'address-line2',
                        }}
                      />
                    </FormColumn>
                    <FormColumn>
                      <TextInput
                        id="postalCode"
                        name="postalCode"
                        readOnly={readOnly}
                        label={formatMessage(intlCommonForm.postalCode)}
                        placeholder={formatMessage(intlCommonForm.postalCodePlaceholder)}
                        inputProps={{
                          type: 'text',
                          autoComplete: 'postal-code',
                        }}
                      />
                    </FormColumn>
                    <FormColumn>
                      <TextInput
                        id="city"
                        name="city"
                        readOnly={readOnly}
                        label={formatMessage(intlCommonForm.city)}
                        placeholder={formatMessage(intlCommonForm.cityPlaceholder)}
                        inputProps={{
                          type: 'text',
                          autoComplete: 'country-name',
                        }}
                      />
                    </FormColumn>
                  </FormGrid>
                  <Box display="flex" justifyContent="space-between" mt={4}>
                    <Button onClick={() => onCancel(formik)} type="button" variant="secondary" size={40}>
                      {formatMessage(intlCommonForm.cancel)}
                    </Button>
                    <Button type="submit" size={40}>
                      {formatMessage(intlCommonForm.confirm)}
                    </Button>
                  </Box>
                </React.Fragment>
              )}
            </RecapWrapper>
          </Form>
        )
      }}
    </Formik>
  )
}
