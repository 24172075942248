import React from 'react'

import styled, { createGlobalStyle } from 'styled-components'

import { COLORS, Typography } from '@themenu/design-system'

export const AddMidSection = styled((props) => <div {...props} />)`
  display: flex;
  justify-content: center;
  padding-top: 20px;
`

export const GlobalStyle = createGlobalStyle`
* {
    font-style: normal;
    font-weight: normal;
  }
`

export const MidsContainer = styled((props) => <div {...props} direction="column" spacing={3} />)`
  background: ${COLORS.WHITE['200']};
  border-radius: 12px;
  padding: 27px;
  margin-bottom: 24px;
  margin-top: 32px;
`

export const MidListItem = styled((props) => (
  <div item xs={12} alignContent="center" justify="space-between" {...props} />
))`
  background: none;
  border-bottom: 1px solid ${COLORS.WHITE['600']};
  padding: 10px;
  display: flex;
  margin-bottom: 8px;

  &.error {
    border-color: red !important;

    & input {
      color: red !important;
    }
  }
`

export const MidListItemText = styled((props) => <Typography {...props} variant="h6" />)`
  font-size: 15px;
  line-height: 20px;
  display: flex;
  cursor: pointer;
`

export const MidsMainText = styled((props) => <Typography {...props} variant="h6" />)`
  margin: 5px 0;
  font-size: 15px !important;
  line-height: 24px;
  color: ${COLORS.PURPLE['900']};
`

export const MidsMainTitle = styled((props) => <Typography {...props} variant="h4" />)`
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: ${COLORS.PURPLE['900']};
`

export const MidsText = styled((props) => <Typography {...props} variant="h6" />)`
  font-weight: 600;
  font-size: 15px !important;
  line-height: 24px;
  color: #ffffff;
  cursor: pointer;
`
