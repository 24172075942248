import { electronicFormatIBAN, isValidBIC, isValidIBAN } from 'ibantools'

import Yup from '@common/utils/yup'

type Step4BankingInfo = {
  iban: string
  bic: string
}

export const formFieldsValidation = (
  defaultNonEmptyField: string,
  invalidIban: string,
  invalidBic: string
): Yup.SchemaOf<Step4BankingInfo> => {
  return Yup.object().shape({
    iban: Yup.string()
      .required(defaultNonEmptyField)
      .transform(function (value) {
        return this.isType(value) && value !== null ? electronicFormatIBAN(value) : value
      })
      .test('validateIban', invalidIban, (value) => (value ? isValidIBAN(value) : false))
      .default(''),
    bic: Yup.string()
      .required(defaultNonEmptyField)
      .test('validateBic', invalidBic, (value) => (value ? isValidBIC(value) : false))
      .default(''),
  })
}
