import axios, { AxiosResponse } from 'axios'

import { TrackingParams } from '../services/analytics/trackingParams'
import { LocalMerchant } from '@src/common/utils/format'
import { UserContact, CompanyInfo } from '@src/store/onboarding/OnboardingState'
import { PardotProspect, Company, BillingInfo, Contact } from '@tunnel-types/globals'

interface Data {
  email: string
  phone: string
  firstName: string
  lastName: string
}

interface ProspectData {
  email: string
}

interface Response {
  status: number
}

export const sendDataToPardot = async (data: Data): Promise<AxiosResponse<Response>> => {
  return await axios.post<Response>('api/pardot/representative-info', {
    email: data.email,
    phone: data.phone,
    firstname: data.firstName,
    lastname: data.lastName,
  })
}

export const getProspect = async (prospect: ProspectData): Promise<PardotProspect> => {
  const { data } = await axios.get<PardotProspect>('api/pardot/prospect', {
    params: {
      email: prospect.email,
    },
  })

  return data
}

export const submitCompanyInfo = async (
  userContact: UserContact,
  merchant: LocalMerchant,
  company: Company
): Promise<Response> => {
  const formattedCompany = {
    email: userContact.email,
    company: merchant.companyLegalName || '',
    city: company.billingAddress?.city || '',
    country: company.billingAddress?.country || '',
    street: company.billingAddress?.street || '',
    zip: company.billingAddress?.zip || '',
    siret: merchant.immatriculation,
  }

  const { data } = await axios.post('api/pardot/company-info', formattedCompany)

  return data
}

export const submitBankInfo = async (userContact: Contact, billingInfo: BillingInfo): Promise<Response> => {
  const formattedBankInfo = {
    email: userContact.email,
    IBAN: billingInfo.iban,
    BIC: billingInfo.bic,
  }

  const { data } = await axios.post('api/pardot/bank-info', formattedBankInfo)

  return data
}

export const submitContractInfo = async (userContact: UserContact, companyInfo: CompanyInfo): Promise<Response> => {
  const formattedContract = {
    email: userContact.email,
    company: companyInfo.legalName ?? '',
    corporate_form: companyInfo.socialForm ?? '',
    share_capital: '',
    ville_immatriculation_rcs: companyInfo.cityofRegistration ?? '',
    siret: companyInfo.siret,
    vat_number: companyInfo.vatNumber ?? '',
    first_name: userContact.firstName ?? '',
    last_name: userContact.lastName ?? '',
    phone: userContact.phone ?? '',
    street: companyInfo?.address?.address ?? '',
    city: companyInfo?.address?.city ?? '',
    zip: companyInfo?.address?.postalCode ?? '',
  }

  const { data } = await axios.post('api/pardot/contract-info', formattedContract)

  return data
}

export const submitSignatureInfo = async (userContact: Contact): Promise<Response> => {
  const formattedSignature = {
    email: userContact.email,
  }

  const { data } = await axios.post('api/pardot/signature-info', formattedSignature)

  return data
}

export const submitTrackingParams = async (
  trackingParams: TrackingParams,
  userContact: Contact
): Promise<AxiosResponse<Response>> => {
  return await axios.post<Response>('api/pardot/signature-info', {
    email: userContact.email,
    ...trackingParams,
  })
}
