import { defineMessages } from 'react-intl'

const intlRecapCompanyForm = defineMessages({
  title: {
    id: 'step_3_recap.company_form.information',
  },
  commercialName: {
    id: 'step_3_recap.company_form.commercial_name',
  },
  commercialNamePlaceholder: {
    id: 'step_3_recap.company_form.commercial_name.placeholder',
  },
  legalName: {
    id: 'step_3_recap.company_form.legal_name',
  },
  legalNamePlaceholder: {
    id: 'step_3_recap.company_form.legal_name.placeholder',
  },
  socialForm: {
    id: 'step_3_recap.company_form.social_form',
  },
  socialFormPlaceholder: {
    id: 'step_3_recap.company_form.social_form.placeholder',
  },
  siret: {
    id: 'step_3_recap.company_form.siret',
  },
  siretPlaceholder: {
    id: 'step_3_recap.company_form.siret.placeholder',
  },
  vat: {
    id: 'step_3_recap.company_form.vat',
  },
  vatPlaceholder: {
    id: 'step_3_recap.company_form.vat.placeholder',
  },
})

export default intlRecapCompanyForm
