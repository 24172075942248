import { useState } from 'react'

import { Form, Formik, FormikProps } from 'formik'
import { useIntl } from 'react-intl'

import { Box, Button } from '@themenu/design-system'

import intlRecapCompanyForm from './RecapCompanyForm.i18n'
import Yup from '@common/utils/yup'
import { TextInput } from '@components/index'
import { intlCommonForm, intlValidation } from '@i18n/intlMessages'
import Sentry from '@lib/sentry'
import { FormColumn, FormGrid, RecapHeader, RecapWrapper, StyledPenIcon } from '@shared/components'
import { ActionType, useDispatchOnboardingAction, useOnboardingState } from '@src/store'
import { CompanyInfo } from '@src/store/onboarding/OnboardingState'
import { AccountOrLeadInfo } from '@tunnel-types/globals'

export const RecapCompanyFormValidationSchema = Yup.object().shape({
  name: Yup.string().required(),
  socialForm: Yup.string().required(),
  legalName: Yup.string().required(),
  siret: Yup.string()
    .required()
    .min(14)
    .max(14)
    .matches(/^\d+$/, () => intlValidation.siret),
  vatNumber: Yup.string().required(),
})

export default function RecapCompanyForm(): JSX.Element {
  const { formatMessage } = useIntl()
  const { companyInfo, salesforceInfo } = useOnboardingState()
  const dispatchOnboardingAction = useDispatchOnboardingAction()

  const [readOnly, setReadOnly] = useState<boolean>(RecapCompanyFormValidationSchema.isValidSync(companyInfo))

  const onCancel = (formik: FormikProps<CompanyInfo>): void => {
    formik.setValues(companyInfo)
    setReadOnly(true)
  }

  const onSubmit = async (values: CompanyInfo): Promise<void> => {
    try {
      dispatchOnboardingAction({
        type: ActionType.UPDATE_SALESFORCE_INFO,
        payload: {
          account: {
            ...(salesforceInfo.account as AccountOrLeadInfo),
            company_type: values.socialForm || '',
            billing_info: {
              company_legal_name: values.legalName,
              vat_number: values.vatNumber,
              immatriculation: values.siret,
              iban: '',
              bic: '',
            },
          },
          owner: salesforceInfo.owner,
        },
      })

      dispatchOnboardingAction({
        type: ActionType.UPDATE_COMPANY_INFO,
        payload: values,
      })

      setReadOnly(true)
    } catch (error) {
      Sentry.withScope((scope) => {
        scope.setContext('/Home/Step3ContractInfo/RecapCompanyForm#submitUserContact', {
          ...values,
        })
        Sentry.captureException(error)
      })
    }
  }

  return (
    <Formik
      initialValues={companyInfo}
      validationSchema={RecapCompanyFormValidationSchema}
      onSubmit={onSubmit}
      validateOnMount
      enableReinitialize
    >
      {(formik: FormikProps<CompanyInfo>) => {
        return (
          <Form autoComplete="on">
            <RecapWrapper error={Object.keys(formik.errors).length !== 0}>
              <RecapHeader
                title={formatMessage(intlRecapCompanyForm.title)}
                action={
                  readOnly ? (
                    <Button type="submit" variant="secondary" size={40} onClick={() => setReadOnly(false)}>
                      <StyledPenIcon />
                      {formatMessage(intlCommonForm.edit)}
                    </Button>
                  ) : undefined
                }
              />
              <FormGrid>
                <FormColumn>
                  <TextInput
                    id="name"
                    name="name"
                    readOnly={readOnly}
                    label={formatMessage(intlRecapCompanyForm.commercialName)}
                    placeholder={formatMessage(intlRecapCompanyForm.commercialNamePlaceholder)}
                    inputProps={{
                      type: 'text',
                    }}
                  />
                </FormColumn>
                <FormColumn>
                  <TextInput
                    id="legalName"
                    name="legalName"
                    readOnly={readOnly}
                    label={formatMessage(intlRecapCompanyForm.legalName)}
                    placeholder={formatMessage(intlRecapCompanyForm.legalNamePlaceholder)}
                    inputProps={{
                      type: 'text',
                    }}
                  />
                </FormColumn>
                <FormColumn>
                  <TextInput
                    id="socialForm"
                    name="socialForm"
                    readOnly={readOnly}
                    label={formatMessage(intlRecapCompanyForm.socialForm)}
                    placeholder={formatMessage(intlRecapCompanyForm.socialFormPlaceholder)}
                    inputProps={{
                      type: 'text',
                    }}
                  />
                </FormColumn>
                <FormColumn>
                  <TextInput
                    id="siret"
                    name="siret"
                    readOnly={readOnly}
                    label={formatMessage(intlRecapCompanyForm.siret)}
                    placeholder={formatMessage(intlRecapCompanyForm.siretPlaceholder)}
                    inputProps={{
                      type: 'text',
                      maxLength: '14',
                    }}
                  />
                </FormColumn>
                <FormColumn>
                  <TextInput
                    id="vatNumber"
                    name="vatNumber"
                    readOnly={readOnly}
                    label={formatMessage(intlRecapCompanyForm.vat)}
                    placeholder={formatMessage(intlRecapCompanyForm.vatPlaceholder)}
                    inputProps={{
                      type: 'text',
                    }}
                  />
                </FormColumn>
              </FormGrid>
              {!readOnly && (
                <Box display="flex" justifyContent="space-between" mt={4}>
                  <Button onClick={() => onCancel(formik)} type="button" variant="secondary" size={40}>
                    {formatMessage(intlCommonForm.cancel)}
                  </Button>
                  <Button type="submit" size={40}>
                    {formatMessage(intlCommonForm.confirm)}
                  </Button>
                </Box>
              )}
            </RecapWrapper>
          </Form>
        )
      }}
    </Formik>
  )
}
