import { useEffect } from 'react'

import { useRouter } from 'next/router'

import { Step, TunnelType } from '@common/constants'
import {
  setInitialDataLayerCustomDimensions,
  setStepDataLayerCustomDimensions,
} from '@services/client/analytics/gtmDatalayer'

export function usePushDataLayer(): void {
  const { query } = useRouter()

  useEffect(() => {
    const type = query.type as TunnelType
    setInitialDataLayerCustomDimensions(type)
  }, [query.type])

  useEffect(() => {
    const step = query.step as Step
    setStepDataLayerCustomDimensions(step)
  }, [query.step])
}
