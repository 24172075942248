import { URLSearchParams } from 'url'

import { GetServerSideProps, NextPage } from 'next'

import { HTTP_BAD_REQUEST_CLIENT_ERROR } from '../common/constants'
import { Home } from '../pages-components/Home/Home'
import ErrorPage from '../pages/_error'
import { fetchSalesforceAccount, fetchSalesforceLead } from '@services/server/affiliates'
import { getSalesforceAccountOwner, getSalesforceLeadOwner } from '@services/server/salesforce'
import { ErrorCode } from '@src/common/errors/ErrorCode'
import {
  getTunnelUseCaseStatus,
  OnboardingRouteParams,
  readOnboardingRouteParams,
  TunnelType,
} from '@src/common/navigation'
import { SalesforceInfo } from '@tunnel-types/globals'

async function fetchSalesforceInfo({
  salesforceId,
  tunnelType,
}: OnboardingRouteParams): Promise<SalesforceInfo | null> {
  if (!salesforceId) {
    return null
  }

  if (tunnelType === TunnelType.ACCOUNT) {
    const [account, owner] = await Promise.all([
      fetchSalesforceAccount(salesforceId),
      getSalesforceAccountOwner(salesforceId),
    ])

    return { account: account.salesforce_account, owner }
  }

  if (tunnelType === TunnelType.LEAD) {
    const [account, owner] = await Promise.all([
      fetchSalesforceLead(salesforceId),
      getSalesforceLeadOwner(salesforceId),
    ])

    return { account: account.salesforce_lead, owner }
  }

  return null
}

type Props = {
  salesforceInfo?: SalesforceInfo
  statusCode?: number
}

const HomePage: NextPage<Props> = ({ salesforceInfo, statusCode }) => {
  if (statusCode && statusCode >= HTTP_BAD_REQUEST_CLIENT_ERROR) {
    return <ErrorPage statusCode={statusCode} />
  }

  return <Home salesforceInfoFromUrl={salesforceInfo} />
}

export const getServerSideProps: GetServerSideProps<Props> = async (context) => {
  const query = context.query
  const params = readOnboardingRouteParams(query)

  try {
    const salesforceInfo = await fetchSalesforceInfo(params)
    const useCaseStatus = getTunnelUseCaseStatus(query, salesforceInfo?.account)

    if (useCaseStatus.isIncomplete) {
      const searchParams = new URLSearchParams({
        ...Object(query),
        error: ErrorCode.MISSING_INFORMATION_SALESFORCE_ID,
      })

      return {
        redirect: {
          destination: `/${context.locale}/missing-information?${searchParams}`,
          permanent: false,
        },
      }
    }

    if (useCaseStatus.isAlreadySigned && salesforceInfo?.account) {
      const searchParams = new URLSearchParams({
        ...Object(query),
        name: salesforceInfo.account.name,
      })

      return {
        redirect: {
          destination: `/${context.locale}/already-affiliated?${searchParams}`,
          permanent: false,
        },
      }
    }

    if (useCaseStatus.isUnknownStep) {
      const searchParams = new URLSearchParams({
        ...Object(query),
        type: params.tunnelType,
        step: params.currentStep,
        ...(params.salesforceId ? { id: params.salesforceId } : null),
      })

      return {
        redirect: {
          destination: `/${context.locale}?${searchParams}`,
          permanent: false,
        },
      }
    }

    return {
      props: salesforceInfo ? { salesforceInfo } : {},
    }
  } catch (err) {
    const searchParams = new URLSearchParams({
      ...Object(query),
      error: ErrorCode.MISSING_INFORMATION_ERROR_FETCH_SALESFORCE,
    })

    return {
      redirect: {
        destination: `/${context.locale}/missing-information?${searchParams}`,
        permanent: false,
      },
    }
  }
}

export default HomePage
