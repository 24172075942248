import TagManager from 'react-gtm-module'

import { Step, TunnelType } from '@common/constants'

const upperCaseFirstLetterWithSpace = (text: string): string => {
  const splitText = text.split('-').join(' ')
  return splitText.charAt(0).toUpperCase() + splitText.slice(1)
}

export const setInitialDataLayerCustomDimensions = (funnelType: TunnelType): void => {
  if (!funnelType) return

  TagManager.dataLayer({
    dataLayer: {
      market: 'France',
      site_type: 'Funnels',
      page_typology: 'Affiliates',
      page_category: `Onboarding ${funnelType}`,
    },
  })
}

export const setStepDataLayerCustomDimensions = (stepName: Step): void => {
  if (!stepName) return

  TagManager.dataLayer({
    dataLayer: {
      page_sub_category: upperCaseFirstLetterWithSpace(stepName),
    },
  })
}
