import { NextRouter } from 'next/router'

import { omitBy, isNil } from 'lodash'

import { Step, TunnelType } from '../constants'

export const updateStepRouteQueryParams = (router: NextRouter, nextStep: Step): void => {
  const type = Object.values(TunnelType).includes(router.query?.type as TunnelType)
    ? router.query.type
    : TunnelType.STANDALONE

  const { id, utm_medium, utm_source, utm_campaign, utm_content } = Object(router.query)

  const query = omitBy({ id, type, step: nextStep, utm_medium, utm_source, utm_campaign, utm_content }, isNil)

  router.push({ query }, undefined, { shallow: true })
}
